import { Delete, Edit, Image } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
import StyledButton from "../../components/StyledButton";
import { datatableRowOptions } from "../../config/constant";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";

const initialState = {
  page: 0,
  total: 0,
  limit: 5,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Article = () => {
  const [state, setState] = useState(initialState);

  const getArticles = async (page = 1, limit = state.limit) => {
    const articles = await API.get(`articles?page=${page}&limit=${limit}`, {});
    setState({
      ...state,
      isLoading: true,
    });

    setState({
      ...state,
      limit,
      page: articles.meta.currentPage,
      total: articles.meta.totalItems,
      data: articles.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getArticles();
  }, []);

  const dialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteArticlePopUpDialog, setDeleteArticlePopUpDialog] =
    useState(dialogInitialState);

  const [viewArticleDialog, setViewArticleDialog] = useState({
    isOpen: false,
    rowData: null,
  });

  console.log(viewArticleDialog);
  const handleDeleteArticlePopUp = (rowData) => {
    setDeleteArticlePopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteArticle = async () => {
    const article = deleteArticlePopUpDialog.rowData;
    await API.remove(`articles/${article.sleepArticleUniqueId}`);
    setDeleteArticlePopUpDialog(dialogInitialState);
    await getArticles(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteArticlePopUpDialog(dialogInitialState);
    setViewArticleDialog({ isOpen: false, rowData: null });
  };

  const columns = [
    {
      name: "sleepArticleUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "banner",
      label: "Banner",
      options: {
        customBodyRender: (value) => {
          if (value) return <img className="img" src={value} alt={"logo"} />;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "subtitle",
      label: "Subtitle",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];
          console.log(data);
          return (
            <Button
              onClick={() =>
                setViewArticleDialog({
                  isOpen: true,
                  rowData: data,
                })
              }
              size="small"
              variant="outlined"
              sx={{ textTransform: "capitalize", fontSize: "0.875rem" }}
              autoFocus
            >
              View
            </Button>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? moment(new Date(value)).format("D MMM YYYY") : "";
        },
        filter: true,
        sort: false,
      },
    },
    {
      name: "sleepArticleUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteArticlePopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/articles/${data.sleepArticleUniqueId}`, {
                    state: { article: data },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   console.log("Changes.....");
      //   await getArticles(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getArticles(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getArticles(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddArticle = () => {
    navigate("../../admin/article");
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton label="Add Article" onClick={navigateAddArticle} />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteArticlePopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteArticle}
        title="Delete article?"
        body="Are you sure, you want to delete article permanently?"
      />

      <Dialog
        open={viewArticleDialog.isOpen}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {viewArticleDialog.rowData?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <ImageListItem
              key={viewArticleDialog.rowData?.banner}
              width="120px"
            >
              <img
                width="120px"
                src={`${viewArticleDialog.rowData?.banner}`}
                loading="lazy"
                alt="test"
              />
              <ImageListItemBar
                title={viewArticleDialog.rowData?.title}
                subtitle={viewArticleDialog.rowData?.subtitle}
              />
            </ImageListItem> */}
            <div
              dangerouslySetInnerHTML={{
                __html: viewArticleDialog.rowData?.description,
              }}
            ></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handlePopUpClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Sleep Articles" />;
};

export default Article;
