import "./SideBar.scss";
import React, { useEffect, useState } from "react";
import variables from "../css/variables.scss";
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Container,
  ListItemButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Collapse,
} from "@mui/material";
import {
  ExpandLessRounded,
  ExpandMoreRounded,
  Menu,
} from "@mui/icons-material";
import Logo from "../assets/images/logo-f.png";
import SleepiaLogo from "../assets/images/sleepia.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import { useAuthContext } from "../hooks/useAuthContext";
import { hasChildren } from "./MenuItem";
import { StyledLink } from "../components/StyledLink";
import CategoryIcon from "@mui/icons-material/Category";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ArticleIcon from "@mui/icons-material/Article";
import * as API from "../services/Api";
import DreamAnalysisIcon from "@mui/icons-material/SettingsSystemDaydream";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PsychologyAltIcon from "@mui/icons-material/PsychologyAlt";
// import AutoStoriesIcon from "@mui/icons-material/AutoStories";

const drawerWidth = 260;

const SingleLevel = ({ item }) => {
  return (
    <StyledLink to={item.link}>
      <ListItemButton key={item.title}>
        <ListItemIcon
          ml={6}
          sx={{
            color: "white",
            opacity: 0.7,
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          sx={{ color: "white", opacity: 0.7 }}
        />
      </ListItemButton>
    </StyledLink>
  );
};

const MultiLevel = ({ item }) => {
  const { pathname } = useLocation();

  console.log(pathname);
  const { items: children } = item;
  const [openSubMenu, setOpenSubMenu] = useState(false);

  const handleOpenCloseSubMenu = () => {
    // if (openSubMenu) {
    //   setOpenSubMenu(false);
    // } else {
    //   setOpenSubMenu(true);
    // }
    setOpenSubMenu(!openSubMenu);
  };

  const openSubMenuItems =
    item?.items?.length > 3
      ? [
          "/admin/category",
          "/admin/subcategories",
          "/admin/musics/white-noise",
          "/admin/musics/white-noise/mixes",
          "/admin/musics/mixes",
          "/admin/mixes",
          "/admin/musics/music",
          "/admin/musics/meditation",
          "/admin/musics",
          "/admin/music-mixes",
        ]
      : // : item?.items?.length > 3
        // ? [
        //     "/admin/privacy-policy",
        //     "/admin/teams-of-service",
        //     "/admin/faq",
        //     // "/admin/notification",
        //     // "/admin/notifications",
        //   ]
        [
          "/admin/email-push",
          "/admin/send-email",
          "/admin/push-emails",
          "/admin/notification",
          "/admin/notifications",
        ];

  return (
    <React.Fragment>
      <ListItemButton
        key={item.title}
        selected={item.link === "t"}
        onClick={handleOpenCloseSubMenu}
      >
        <ListItemIcon
          sx={{
            color: "white",
            opacity: 0.7,
          }}
        >
          {item.icon}
        </ListItemIcon>
        <ListItemText
          primary={item.title}
          sx={{ color: "white", opacity: 0.7 }}
        />
        {openSubMenu ? (
          <ExpandLessRounded
            sx={{
              color: "white",
            }}
          />
        ) : (
          <ExpandMoreRounded
            sx={{
              color: "white",
            }}
          />
        )}
      </ListItemButton>
      <Collapse
        in={
          openSubMenuItems.includes(pathname.split("/", 3).join("/"))
            ? openSubMenu === true
              ? false
              : true
            : openSubMenu
        }
        timeout="auto"
        unmountOnExit
      >
        {/* <Collapse in={openSubMenu} timeout="auto" unmountOnExit> */}
        <List component="div" disablePadding>
          {children.map((child, key) => (
            <MenuItem key={key} item={child} />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};

const MenuItem = ({ item }) => {
  const Component = hasChildren(item) ? MultiLevel : SingleLevel;
  return <Component item={item} />;
};

const SideBar = (props) => {
  const { window, pageContent, pageTitle } = props;
  const { pathname } = useLocation();

  const [mobileOpen, setMobileOpen] = useState(false);

  const [open, setOpen] = useState(false);
  const [categories, setCategories] = useState([]);

  const { dispatch } = useAuthContext();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logout = () => {
    localStorage.removeItem("user");

    dispatch({ type: "LOGOUT" });
  };

  const getCategories = async () => {
    const data = await API.get(`categories?isShowOnAdminPanel=true`);

    console.log({ data });
    // eslint-disable-next-line array-callback-return
    // const updatedCategories = data.map((c) => {
    //   if (c?.type !== "program-screen") {
    //     return {
    //       title: c.name,
    //       link: `/admin/category/${c.categoryUniqueId}`,
    //     };
    //   }
    // });
    const updatedCategories = data
      .filter((c) => c?.type !== "program-screen")
      .map((c) => ({
        title: c.name,
        link: `/admin/category/${c.categoryUniqueId}`,
      }));

    console.log({ updatedCategories });

    setCategories(updatedCategories);
  };

  const notifications = [
    {
      title: "Push Notifications",
      link: `/admin/notifications`,
    },
    {
      title: "Push Emails",
      link: `/admin/push-emails`,
    },
  ];

  // const pages = [
  //   {
  //     title: "Privacy Policy",
  //     link: `/admin/privacy-policy`,
  //   },
  //   {
  //     title: "Terms of Service",
  //     link: `/admin/teams-of-service`,
  //   },
  //   {
  //     title: "FAQ",
  //     link: `/admin/faq`,
  //   },
  // ];

  useEffect(() => {
    getCategories();
  }, []);

  const menuItems = [
    {
      title: "Categories",
      icon: <CategoryIcon />,
      items: categories,
    },
    {
      title: "Articles",
      icon: <ArticleIcon />,
      link: "/admin/articles",
      items: [],
    },
    {
      title: "Banners",
      icon: <ViewCarouselIcon />,
      link: "/admin/banners",
      items: [],
    },
    {
      title: "Dream Interpretations",
      icon: <DreamAnalysisIcon />,
      link: "/admin/dream-analysis",
      items: [],
    },
    {
      title: "Precautions",
      icon: <PsychologyAltIcon />,
      link: "/admin/precautions",
      items: [],
    },
    {
      title: "Notifications",
      icon: <NotificationsActiveIcon />,
      link: "/admin/notifications",
      items: notifications,
    },
    // {
    //   title: "Pages",
    //   icon: <AutoStoriesIcon />,
    //   link: "/admin/notifications",
    //   items: pages,
    // },
    // {
    //   title: "Email",
    //   icon: <NotificationsActiveIcon />,
    //   link: "/admin/emails",
    //   items: [],
    // },
    {
      title: "Setting",
      icon: <SettingsIcon />,
      link: "/admin/setting",
      items: [],
    },
  ];

  const drawer = (
    <div className="bg-purple">
      <Box sx={{ margin: "auto" }} className="logo-section">
        <img src={Logo} className="logo" alt={"logo"} />
      </Box>
      <Divider sx={{ backgroundColor: "white", opacity: 0.1 }} />
      <List>
        {menuItems.map((menu, index) => (
          <MenuItem key={index} item={menu} />
        ))}
      </List>
      <Divider sx={{ backgroundColor: "white", opacity: 0.1 }} />
      <List>
        <StyledLink onClick={() => handleOpen()}>
          <ListItemButton>
            <ListItemIcon
              sx={{
                color: "white",
                opacity: 0.7,
              }}
            >
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText
              primary="Logout"
              sx={{ color: "white", opacity: 0.7 }}
            />
          </ListItemButton>
        </StyledLink>
      </List>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Logout</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to logout?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={logout} autoFocus>
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: "transparent",
          boxShadow: "none",
        }}
      >
        <Toolbar
          sx={{
            backdropFilter: "blur(20px)",
            backgroundColor: "transparent",
          }}
        >
          <Container
            maxWidth="xl"
            sx={{
              paddingRight: 0,
              paddingLeft: 0,
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" }, color: "#000" }}
            >
              <Menu />
            </IconButton>
            <div className="center">
              <img
                className="hidden"
                width={100}
                src={SleepiaLogo}
                alt="logo"
              />
              <Typography
                variant="h6"
                noWrap
                component="div"
                className="show"
                color={variables.baseColor}
                sx={{ fontWeight: "bold" }}
              >
                {pageTitle}
              </Typography>
            </div>
          </Container>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{
          width: { sm: drawerWidth },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          className="bg-purple"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: variables.baseColor,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          className="bg-purple"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: variables.baseColor,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          height: "100vh",
          backgroundColor: "background.default",
        }}
      >
        <Toolbar />
        <Container maxWidth="xl" sx={{ paddingLeft: 0, paddingRight: 0 }}>
          {pageContent}
        </Container>
      </Box>
    </Box>
  );
};

export default SideBar;
