import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/Loader";
import { useAuthContext } from "./hooks/useAuthContext";
import PageNotFound from "./pages/PageNotFound";
import AddBanner from "./pages/banner/forms/AddBanner";
import Category from "./pages/category/Category";
import AddSubcategory from "./pages/category/forms/AddSubcategory";
import EditBanner from "./pages/banner/forms/EditBanner";
import EditSubcategory from "./pages/category/forms/EditSubcategory";
import AddMusic from "./pages/category/forms/AddMusic";
import WhiteNoiseMusic from "./pages/category/WhiteNoise/WhiteNoiseMusic";
import Music from "./pages/category/Music/Music";
import Meditation from "./pages/category/Meditation/Meditation";
import Mixes from "./pages/category/WhiteNoise/Mixes/Mixes";
import AddMix from "./pages/category/WhiteNoise/Mixes/AddMix";
import EditMix from "./pages/category/WhiteNoise/Mixes/EditMix";
import EditMusic from "./pages/category/forms/EditMusic";
import AddArticle from "./pages/article/forms/AddArticle";
import EditArticle from "./pages/article/forms/EditArticle";
import Article from "./pages/article/Article";
import MusicMix from "./pages/music-mixes/MusicMix";
import Banner from "./pages/banner/Banner";
import Setting from "./pages/setting/Setting";
import DreamAnalysis from "./pages/dream-analysis/DreamAnalysis";
import AddDream from "./pages/dream-analysis/forms/AddDream";
import EditDream from "./pages/dream-analysis/forms/EditDream";
import Notification from "./pages/notification/Notification";
import AddNotification from "./pages/notification/forms/AddNotification";
import Emails from "./pages/email/Emails";
import SendEmail from "./pages/email/forms/SendEmail";
import EditNotification from "./pages/notification/forms/EditNotification";
import EditEmail from "./pages/email/forms/EditEmail";
import Precautions from "./pages/precautions/Precautions";
import AddPrecautions from "./pages/precautions/forms/AddPrecautions";
import EditPrecautions from "./pages/precautions/forms/EditPrecautions";

const Login = React.lazy(() => {
  return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
    import("./pages/auth/Login")
  );
});

// const MusicMix = React.lazy(() => {
//   return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
//     import("./pages/music-mixes/MusicMix")
//   );
// });

// const Article = React.lazy(() => {
//   return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
//     import("./pages/article/Article")
//   );
// });

// const Banner = React.lazy(() => {
//   return new Promise((resolve) => setTimeout(resolve, 500)).then(() =>
//     import("./pages/banner/Banner")
//   );
// });

export const theme = createTheme({
  components: {
    palette: {
      primary: {
        main: "#7447FF", // main color
      },
      secondary: {
        main: "#7447FF",
      },
    },
    inputLabel: {
      color: "lightgray",
      "&$inputFocused": {
        color: "orange",
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#7447FF",
          lineHeight: "2.3",
          borderColor: "#7447FF",
          "&:hover": {
            borderColor: "#7447FF",
          },
        },
        containedPrimary: {
          backgroundColor: "#7447FF",
          lineHeight: "2.3",
          "&:hover": {
            backgroundColor: "#7447FF",
          },
        },
      },
    },
  },
});

function App() {
  const { user } = useAuthContext();

  return (
    <Suspense fallback={<Loader />}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Routes>
            <Route
              exact
              path="/"
              element={user ? <Navigate to="../../admin/banners" /> : <Login />}
            />
            <Route
              exact
              path="/admin/login"
              element={user ? <Navigate to="../../admin/banners" /> : <Login />}
            />
            {/* <Route
              exact
              path="/teams-and-condition"
              element={user ? <Navigate to="../../admin/banners" /> : <Login />}
            /> */}

            <Route
              exact
              path="/admin/category/:categoryUniqueId"
              element={
                user ? <Category /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/category/:categoryUniqueId/subcategories"
              element={
                user ? <AddSubcategory /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/subcategories/:subcategoryUniqueId"
              element={
                user ? <EditSubcategory /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/musics/white-noise"
              element={
                user ? <WhiteNoiseMusic /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/musics/white-noise/mixes"
              element={user ? <Mixes /> : <Navigate to="../../admin/login" />}
            />

            <Route
              exact
              path="/admin/musics/mixes"
              element={user ? <AddMix /> : <Navigate to="../../admin/login" />}
            />

            <Route
              exact
              path="/admin/mixes/:mixUniqueId"
              element={user ? <EditMix /> : <Navigate to="../../admin/login" />}
            />

            <Route
              exact
              path="/admin/musics/music"
              element={user ? <Music /> : <Navigate to="../../admin/login" />}
            />

            <Route
              exact
              path="/admin/musics/meditation"
              element={
                user ? <Meditation /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/musics"
              element={
                user ? <AddMusic /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/musics/:musicUniqueId"
              element={
                user ? <EditMusic /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/music-mixes"
              element={
                user ? <MusicMix /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/banners"
              element={user ? <Banner /> : <Navigate to="../../admin/login" />}
            />
            <Route
              exact
              path="/admin/articles"
              element={user ? <Article /> : <Navigate to="../../admin/login" />}
            />
            <Route
              exact
              path="/admin/notifications"
              element={
                user ? <Notification /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/email-push/:id"
              element={
                user ? <EditEmail /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/notifications/:id"
              element={
                user ? (
                  <EditNotification />
                ) : (
                  <Navigate to="../../admin/login" />
                )
              }
            />

            <Route
              exact
              path="/admin/notification"
              element={
                user ? <AddNotification /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/push-emails"
              element={user ? <Emails /> : <Navigate to="../../admin/login" />}
            />
            <Route
              exact
              path="/admin/send-email"
              element={
                user ? <SendEmail /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/setting"
              element={user ? <Setting /> : <Navigate to="../../admin/login" />}
            />
            <Route
              exact
              path="/admin/dream-analysis"
              element={
                user ? <DreamAnalysis /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/add-dream"
              element={
                user ? <AddDream /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/dream/:dreamUniqueId"
              element={
                user ? <EditDream /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/precautions"
              element={
                user ? <Precautions /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/add-precautions"
              element={
                user ? <AddPrecautions /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/precautions/:precautionUniqueId"
              element={
                user ? <EditPrecautions /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/article"
              element={
                user ? <AddArticle /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/articles/:sleepArticleUniqueId"
              element={
                user ? <EditArticle /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/banner"
              element={
                user ? <AddBanner /> : <Navigate to="../../admin/login" />
              }
            />

            <Route
              exact
              path="/admin/banners/:bannerUniqueId"
              element={
                user ? <EditBanner /> : <Navigate to="../../admin/login" />
              }
            />

            {/* <Route
              exact
              path="/admin/privacy-policy"
              element={
                user ? <Precautions /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/teams-of-service"
              element={
                user ? <Precautions /> : <Navigate to="../../admin/login" />
              }
            />
            <Route
              exact
              path="/admin/faq"
              element={
                user ? <Precautions /> : <Navigate to="../../admin/login" />
              }
            /> */}

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </ThemeProvider>
      </BrowserRouter>
      <ToastContainer />
    </Suspense>
  );
}

export default App;
