import { Box, CircularProgress } from "@mui/material";
import React from "react";

const ButtonCircularProgress = () => {
  return (
    <Box p={1}>
      <CircularProgress
        size={24}
        thickness={3.6}
        sx={{
          color: "#7447FF",
          position: "absolute",
          marginTop: "-12px",
          marginLeft: "-12px",
        }}
      />
    </Box>
  );
};

export default ButtonCircularProgress;
