import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StyledSwitch } from "../../../components/StyledSwitch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import { CategoriesEnum } from "../../../config/enums";
import ButtonCircularProgress from "../../../components/buttonLoader";

const AddMusic = () => {
  const { state } = useLocation();
  const category = state.category;
  const subcategoryId = state.subcategoryId;

  const navigate = useNavigate();
  const navigateCategoriesPage = () => {
    navigate(`/admin/category/${category.categoryUniqueId}`);
  };

  const navigateMusicListingPage = () => {
    navigate(`/admin/musics/${category.type}`, {
      state: { category, subcategoryId: subcategoryId },
    });
  };

  const [title, setTitle] = useState("");
  const [media, setMedia] = useState("");
  const [artist, setArtist] = useState("");

  const [banner, setBanner] = useState("");
  const [isPremium, setIsPremium] = useState(false);
  const [isShowOnPiaScreenDefault, setIsShowOnPiaScreenDefault] =
    useState(false);
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  const storeMusic = async () => {
    console.log(isPremium);
    if (!media) {
      toast.error("Please upload music audio file.");
      return;
    }

    if (!banner) {
      toast.error("Please upload banner file.");
      return;
    }

    if (!title) {
      toast.error("Please enter music title.");
      return;
    }

    if (![CategoriesEnum.WHITE_NOISE].includes(category.type) && !artist) {
      toast.error("Please enter artist name.");
      return;
    }

    let formData = new FormData();

    formData.append("title", title);
    formData.append("isPremium", isPremium);
    formData.append("isShowOnPiaScreenDefault", isShowOnPiaScreenDefault);
    formData.append("categoryId", category.id);
    formData.append("subcategoryId", subcategoryId);
    formData.append("banner", banner);
    formData.append("artist", artist);
    formData.append("media", media);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post("musics", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    });

    setIsLoading(false);
    setButtonText("Save");
    if (res.message && !res.statusCode) {
      if (
        [CategoriesEnum.SOUNDSCAPE, CategoriesEnum.SLEEP_STORY].includes(
          category.type
        )
      ) {
        navigateCategoriesPage();
      } else {
        navigateMusicListingPage();
      }
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Add new story to category "{category.name}".
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            {/* Icon upload */}
            <Box>
              <Typography mb={1} sx={{ color: "gray" }}>
                Upload audio file:
              </Typography>

              <Button
                variant="contained"
                component="label"
                endIcon={<CloudUploadIcon />}
              >
                Upload Audio
                <input
                  onChange={(e) => setMedia(e.target.files[0])}
                  type="file"
                  accept="audio/*"
                  hidden
                  name="icon"
                />
              </Button>

              <br />
              <br />
            </Box>
            {![CategoriesEnum.WHITE_NOISE].includes(category.type) && (
              <Box>
                <Typography mb={1} sx={{ color: "gray" }}>
                  Upload banner file:
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  NOTE: Upload a banner with a width 424px and height 464px (It
                  is preferable to use 424px * 464px.). 
                </Typography>

                <Button
                  variant="contained"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Banner
                  <input
                    onChange={(e) => setBanner(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                    name="icon"
                  />
                </Button>

                <br />
              </Box>
            )}
            {[CategoriesEnum.WHITE_NOISE].includes(category.type) && (
              <Box>
                <Typography mb={1} sx={{ color: "gray" }}>
                  Upload icon file:
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  NOTE: Icon will show when it will show on App Home screen.
                  Upload an icon with a 1:1 aspect ratio (It is preferable to
                  use 72*72px.). 
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Icon
                  <input
                    onChange={(e) => setBanner(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                    name="banner"
                  />
                </Button>

                <br />
              </Box>
            )}
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            {/* audio file title name */}
            <StyledTextField
              label="Music title"
              variant="outlined"
              fullWidth
              placeholder="The story book"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />
            {![CategoriesEnum.WHITE_NOISE].includes(category.type) && (
              <Box>
                <StyledTextField
                  label="Artist name"
                  variant="outlined"
                  fullWidth
                  placeholder="John Doe"
                  type="text"
                  onChange={(e) => setArtist(e.target.value)}
                />
                <br />
              </Box>
            )}

            {![CategoriesEnum.WHITE_NOISE].includes(category.type) && (
              <Box mt={2} sx={{ display: "flex" }}>
                <Typography sx={{ color: "gray" }}>
                  Is default pia music? 
                </Typography>

                <StyledSwitch
                  checked={isShowOnPiaScreenDefault}
                  onChange={(e) =>
                    setIsShowOnPiaScreenDefault(!isShowOnPiaScreenDefault)
                  }
                />
                <br />
              </Box>
            )}

            <Box mt={2} sx={{ display: "flex" }}>
              <Typography sx={{ color: "gray" }}>Is music premium? </Typography>

              <StyledSwitch
                checked={isPremium}
                onChange={(e) => setIsPremium(!isPremium)}
              />
              <br />
            </Box>

            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storeMusic}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateCategoriesPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Add Music" />;
};

export default AddMusic;
