export const SubcaetegoriesTypeEnum = {
  MUSIC: "music",
  MIX: "mix",
};

export const CategoriesEnum = {
  MY_LIBRARY: "my-library",
  WHITE_NOISE: "white-noise",
  MUSIC: "music",
  PREMINUM: "premium",
  SLEEP_STORY: "sleep-story",
  MEDITATION: "meditation",
  SOUNDSCAPE: "soundscape",
};
