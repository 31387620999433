import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StyledSwitch } from "../../../components/StyledSwitch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import { CategoriesEnum } from "../../../config/enums";
import ButtonCircularProgress from "../../../components/buttonLoader";

const AddSubcategory = () => {
  // const { categoryUniqueId } = useParams();

  const { state } = useLocation();
  const category = state.category;

  const navigate = useNavigate();
  const navigateCategoriesPage = () => {
    navigate(`/admin/category/${category.categoryUniqueId}`);
  };

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [isShowOnHomeScreen, setIsShowOnHomeScreen] = useState(false);
  const [type, setType] = useState("music");
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  const storeSubcategory = async () => {
    if (!name) {
      toast.error("Please enter subcategory name.");
      return;
    }

    let formData = new FormData();

    formData.append("name", name);
    formData.append("isShowOnHomeScreen", isShowOnHomeScreen);
    formData.append("categoryId", category.id);
    formData.append("icon", icon);
    formData.append("type", type);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post("subcategories", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${localStorage.getItem("user")}`,
      },
    });

    console.log(res);
    setIsLoading(false);
    setButtonText("Save");
    if (res.message && !res.statusCode) {
      navigateCategoriesPage();
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Add new subcategory to category "{category.name}".
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            {/* Icon upload */}
            {[
              CategoriesEnum.MUSIC,
              CategoriesEnum.MEDITATION,
              CategoriesEnum.SOUNDSCAPE,
            ].includes(category.type) && (
              <Box>
                <Typography mb={1} sx={{ color: "gray" }}>
                  Upload icon file:
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  NOTE: Icon will show when it will show on App Home screen.
                  Upload an icon with a 1:1 aspect ratio (It is preferable to
                  use 72*72px.). 
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Icon
                  <input
                    onChange={(e) => setIcon(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                    name="icon"
                  />
                </Button>

                <br />
              </Box>
            )}
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            {/* Subcategory name */}
            <StyledTextField
              label="Name"
              variant="outlined"
              fullWidth
              placeholder="Healing Music"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />

            <br />
            {[
              CategoriesEnum.MUSIC,
              CategoriesEnum.MEDITATION,
              CategoriesEnum.SOUNDSCAPE,
            ].includes(category.type) && (
              <Box mt={2} sx={{ display: "flex" }}>
                <Typography sx={{ color: "gray" }}>
                  Do you want to see subcategories on the app's home screen? 
                </Typography>

                <StyledSwitch
                  checked={isShowOnHomeScreen}
                  onChange={(e) => setIsShowOnHomeScreen(!isShowOnHomeScreen)}
                />
                <br />
              </Box>
            )}

            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storeSubcategory}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateCategoriesPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Add Subcategory" />;
};

export default AddSubcategory;
