import { Box } from "@mui/system";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import StyledButton from "../../components/StyledButton";
import { datatableRowOptions } from "../../config/constant";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";
import { IconButton } from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import DeleteDialog from "../../components/DeleteDialog";

const initialState = {
  page: 0,
  total: 0,
  limit: 5,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Emails = () => {
  const [state, setState] = useState(initialState);
  const deleteDialogInitialState = {
    isOpen: false,
    rowData: null,
  };

  const [deleteNotificationPopUpDialog, setDeleteNotificationPopUpDialog] =
    useState(deleteDialogInitialState);
  const getEmails = async (page = 1, limit = state.limit) => {
    const notifications = await API.get(
      `push-emails?page=${page}&limit=${limit}`
    );
    setState({
      ...state,
      isLoading: true,
    });

    setState({
      ...state,
      limit,
      page: notifications.meta.currentPage,
      total: notifications.meta.totalItems,
      data: notifications.items,
      isLoading: false,
    });
  };

  const handleDeleteNotificationPopUp = (rowData) => {
    setDeleteNotificationPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  useEffect(() => {
    getEmails();
  }, []);

  const deleteNotification = async () => {
    const notification = deleteNotificationPopUpDialog.rowData;
    await API.remove(`notification/${notification.id}`);
    setDeleteNotificationPopUpDialog(deleteDialogInitialState);
    getEmails(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteNotificationPopUpDialog(deleteDialogInitialState);
  };
  const columns = [
    {
      name: "groupType",
      label: "Group Type",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value ? `${value.text}` : "";
        },
      },
    },
    {
      name: "scheduledAt",
      label: "Schedule At",
      options: {
        // customBodyRender: (value) => {
        //   return value ? moment(value, "X").format("D MMM YYYY hh:mm A") : "";
        // },
        customBodyRender: (value) => {
          if (!value || isNaN(value)) {
            console.error("Invalid or missing timestamp:", value);
            return ""; // or handle the error as needed
          }

          const date = new Date(value * 1000);

          if (isNaN(date.getTime())) {
            console.error("Invalid date:", date);
            return ""; // or handle the error as needed
          }

          const options = {
            timeZone: "UTC",
            year: "numeric",
            month: "short",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            // second: "2-digit",
            hour12: true,
          };
          const utcTime = date.toLocaleString("en-US", options);

          console.log(utcTime);
          return utcTime;
        },
        filter: true,
        sort: false,
      },
    },
    {
      name: "title",
      label: "Email Subject",
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: "body",
      label: "Email Body",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return value;
        },
      },
    },
    {
      name: "notificationFrequencyOptions",
      label: "Notification Repeat Schedule",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "musicUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteNotificationPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/email-push/${data.id}`, {
                    state: { notification: data },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   getEmails(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getEmails(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getEmails(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddNotification = () => {
    navigate("../../admin/send-email");
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton
          label="Add Email Notification"
          onClick={navigateAddNotification}
        />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />
      <DeleteDialog
        open={deleteNotificationPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteNotification}
        title="Delete music?"
        body="Are you sure you want to delete notification?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Email Notification" />;
};

export default Emails;
