import { Button, Card, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api"; //../../services/Api
import ButtonCircularProgress from "../../../components/buttonLoader";
import moment from "moment";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const SendEmail = () => {
  const navigate = useNavigate();
  const navigateNotificationPage = () => {
    navigate("/admin/push-emails");
  };

  const [groupType, setGroupType] = React.useState("");
  const [body, setBody] = useState("");
  const [subject, setSubject] = useState("");
  const [notificationRepeat, setNotificationRepeat] = useState("");
  const [notificationRepeatOption, setNotificationRepeatOption] = useState([]);
  const [scheduledAt, setScheduledAt] = useState("");
  const [buttonText, setButtonText] = useState("Schedule");
  const [userGroups, setUserGroups] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const scheduleEmail = async () => {
    if (!groupType) {
      toast.error("Please select user groups.");
      return;
    }

    if (!scheduledAt) {
      toast.error("Please select notification schedule.");
      return;
    }
    if (!subject) {
      toast.error("Please enter subject.");
      return;
    }
    if (!body) {
      toast.error("Please enter notification body.");
      return;
    }

    setButtonText("Scheduling...");
    setIsLoading(true);

    const timestamp = new Date(scheduledAt.$d);

    // Extract the date and time components
    const year = timestamp.getFullYear();
    const month = String(timestamp.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(timestamp.getDate()).padStart(2, "0");
    const hours = String(timestamp.getHours()).padStart(2, "0");
    const minutes = String(timestamp.getMinutes()).padStart(2, "0");
    const seconds = String(timestamp.getSeconds()).padStart(2, "0");

    // Create the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    console.log(formattedDateTime);

    const res = await API.post(
      "push-emails",
      {
        groupType,
        body,
        title: subject,
        redirectionScreenType: null,
        notificationFrequencyOptions: notificationRepeat,
        // scheduledAt: moment(scheduledAt.$d).utc().unix(),
        scheduledAt: moment
          .utc(formattedDateTime, "YYYY-MM-DD HH:mm:ss")
          .unix(),
        notificationType: "email",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setIsLoading(false);
    setButtonText("Schedule");

    if (res.message && !res.statusCode) {
      navigateNotificationPage();
    }
  };

  const getNotificationConstants = async () => {
    const data = await API.get("notification-constants");
    setUserGroups(data.notificationUserGroups);
  };

  const getNotificationOption = async () => {
    const data = await API.get("notification-repeat-options");
    setNotificationRepeatOption(data);
  };
  useEffect(() => {
    getNotificationConstants();
    getNotificationOption();
  }, []);

  const pageContent = (
    <Box>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Select user group"
              onChange={(e) => setGroupType(e.target.value)}
              select
            >
              {userGroups.map((u) => (
                <MenuItem key={u.id} value={u.id}>
                  {u.text}
                </MenuItem>
              ))}
            </StyledTextField>

            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <MobileDateTimePicker
                  slotProps={{
                    textField: {
                      variant: "outlined",
                    },
                  }}
                  label="Email Notification Schedule"
                  minutesStep={5}
                  onChange={(e) => {
                    setScheduledAt(e);
                  }}
                  sx={{
                    label: {
                      color: "#637381 !important",
                    },
                    width: "100%",
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Email Subject"
              variant="outlined"
              fullWidth
              placeholder="Email Subject"
              type="text"
              onChange={(e) => setSubject(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Email Body"
              variant="outlined"
              fullWidth
              placeholder="Email Body"
              type="text"
              onChange={(e) => setBody(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Notification Repeat Schedule"
              onChange={(e) => setNotificationRepeat(e.target.value)}
              select
            >
              {notificationRepeatOption.map((n) => (
                <MenuItem key={n.value} value={n.value}>
                  {n.text}
                </MenuItem>
              ))}
            </StyledTextField>
            <br />
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={scheduleEmail}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateNotificationPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Email Notification" />;
};

export default SendEmail;
