import axios from "axios";
import { toast } from "react-toastify";

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/admin/`;

axios.defaults.headers.common = {
  "Content-Type": "application/json; charset=utf-8",
  Accept: "application/json; charset=utf-8",
};
const token = localStorage.getItem("user");
console.log(token);
if (token) {
  axios.defaults.headers.common.Authorization = `Bearer ${token}`;
} else {
  delete axios.defaults.headers.common.Authorization;
}

/**
 * Handle Axios response
 * @param res   HTTP Response
 * @returns     Return data
 */
export const getResponse = (res) => {
  if (res && (res.status === 200 || res.status === 201 || res.status === 204)) {
    if (res.status === 201 || res.status === 204) {
      res.data.data = true;

      return res.data;
    }
    return res.data.data;
  }

  if (res && (res.status === 400 || res.status === 401 || res.status === 404)) {
    return res.response.data;
  }

  return res.data;
  // return Promise.reject(error);
  // throw new Error("Some error occur");
};

/**
 * Get request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const get = async (path, params, headers) => {
  try {
    const data = await axios
      .get(
        path,
        { params },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json; charset=utf-8",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(getResponse);

    return data;
  } catch (error) {
    if (error.response?.data) {
      toast.error(error.response?.data?.message);
    } else {
      toast.error(error.message);
    }

    return Promise.reject(error);
  }
  // return new Promise((resolve, reject) => {
  //   try {
  //     axios
  //       .get(path, { params }, { headers })
  //       .then(getResponse)
  //       .then(resolve)
  //       .catch(reject);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });
};

/**
 * Post request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const post = async (path, params, headers) => {
  // return new Promise((resolve, reject) => {
  //   try {
  //     axios
  //       .post(path, params || {}, { headers })
  //       .then(getResponse)
  //       .then(resolve)
  //       .catch(reject);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });

  const data = await axios
    .post(path, params || {}, { headers })
    .then(getResponse)
    .catch((error) => {
      if (error.response?.data) {
        toast.error(error.response?.data?.message);
        return error.response?.data;
      } else {
        toast.error(error.message);
      }

      return Promise.reject(error);
    });

  return data;
};

/**
 * Put request
 * @param path      API url path
 * @param params    Request parameters
 * @param headers   Request headers
 */
export const put = (path, params, headers) => {
  return new Promise((resolve, reject) => {
    try {
      axios
        .put(path, params || {}, { headers })
        .then(getResponse)
        .then(resolve)
        .catch(reject);
    } catch (error) {
      reject(error);
    }
  });
};

export const remove = async (path, params, headers) => {
  // return new Promise((resolve, reject) => {
  //   try {
  //     axios
  //       .delete(path, { data: params }, { headers })
  //       .then(getResponse)
  //       .then(resolve)
  //       .catch(reject);
  //   } catch (error) {
  //     reject(error);
  //   }
  // });

  console.log(path);
  console.log(params);
  console.log(headers);
  try {
    const data = await axios
      .delete(path, { data: params }, { headers })
      .then(getResponse);
    return data;
  } catch (error) {
    if (error.response?.data) {
      toast.error(error.response?.data?.message);
    } else {
      toast.error(error.message);
    }

    return Promise.reject(error);
  }
};
