import { Delete, Edit, Image } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
import StyledButton from "../../components/StyledButton";
import { datatableRowOptions } from "../../config/constant";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";

const initialState = {
  page: 0,
  total: 0,
  limit: 5,
  data: [["Loading Data..."]],
  isLoading: false,
};

const DreamAnalysis = () => {
  const [state, setState] = useState(initialState);

  const getDreams = async (page = 1, limit = state.limit) => {
    const dreams = await API.get(
      `dream-analysis?page=${page}&limit=${limit}`,
      {}
    );
    setState({
      ...state,
      isLoading: true,
    });

    setState({
      ...state,
      limit,
      page: dreams.meta.currentPage,
      total: dreams.meta.totalItems,
      data: dreams.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getDreams();
  }, []);

  const dialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteDreamPopUpDialog, setDeleteDreamPopUpDialog] =
    useState(dialogInitialState);

  const [viewArticleDialog, setViewArticleDialog] = useState({
    isOpen: false,
    rowData: null,
  });

  const handleDeleteDreamPopUp = (rowData) => {
    setDeleteDreamPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteDream = async () => {
    const dream = deleteDreamPopUpDialog.rowData;
    await API.remove(`dream/${dream.dreamUniqueId}`);
    setDeleteDreamPopUpDialog(dialogInitialState);
    getDreams(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteDreamPopUpDialog(dialogInitialState);
    setViewArticleDialog({ isOpen: false, rowData: null });
  };

  const columns = [
    {
      name: "dreamUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        customBodyRender: (value) => {
          if (value) return <img src={value} width={120} alt={"logo"} />;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "url",
    //   label: "Redirection Url",
    //   options: {
    //     filter: false,
    //     sort: false,
    //   },
    // },
    {
      name: "description",
      label: "Description",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];
          console.log(data);
          return (
            <Button
              onClick={() =>
                setViewArticleDialog({
                  isOpen: true,
                  rowData: data,
                })
              }
              size="small"
              variant="outlined"
              sx={{ textTransform: "capitalize", fontSize: "0.875rem" }}
              autoFocus
            >
              View
            </Button>
          );
        },
        filter: true,
        sort: true,
      },
    },

    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? moment(new Date(value)).format("D MMM YYYY") : "";
        },
        filter: true,
        sort: false,
      },
    },
    {
      name: "dreamUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteDreamPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/dream/${data.dreamUniqueId}`, {
                    state: { dream: data },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   getDreams(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getDreams(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getDreams(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddDream = () => {
    navigate("../../admin/add-dream");
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton label="Add Dream" onClick={navigateAddDream} />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteDreamPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteDream}
        title="Delete Dream?"
        body="Are you sure, you want to delete dream permanently?"
      />

      <Dialog
        open={viewArticleDialog.isOpen}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {viewArticleDialog.rowData?.name}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <ImageListItem
              key={viewArticleDialog.rowData?.banner}
              width="120px"
            >
              <img
                width="120px"
                src={`${viewArticleDialog.rowData?.banner}`}
                loading="lazy"
                alt="test"
              />
              <ImageListItemBar
                title={viewArticleDialog.rowData?.title}
                subtitle={viewArticleDialog.rowData?.subtitle}
              />
            </ImageListItem> */}
            <div
              dangerouslySetInnerHTML={{
                __html: viewArticleDialog.rowData?.description,
              }}
            ></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handlePopUpClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return (
    <SideBar pageContent={pageContent} pageTitle="Dream Interpretations" />
  );
};

export default DreamAnalysis;
