import { Avatar, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as API from "../../../services/Api";
import {
  ArrowBackIos,
  Delete,
  LibraryAdd,
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
  Edit,
} from "@mui/icons-material";
import SideBar from "../../../layouts/Sidebar";
import { datatableRowOptions } from "../../../config/constant";
import DeleteDialog from "../../../components/DeleteDialog";
import StyledButton from "../../../components/StyledButton";

const initialState = {
  page: 0,
  total: 0,
  limit: 15,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Meditation = (props) => {
  const { state } = useLocation();
  const category = state.category;
  const subcategoryId = state.subcategoryId;

  console.log("category", category);
  const [dataState, setState] = useState(initialState);

  const deleteDialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteMusicPopUpDialog, setDeleteMusicPopUpDialog] = useState(
    deleteDialogInitialState
  );

  const getMusics = async (page = 1, limit = dataState.limit) => {
    setState({
      ...dataState,
      isLoading: true,
    });

    console.log(category);
    const musics = await API.get(
      `musics/${category.type}?subcategoryId=${subcategoryId}&page=${page}&limit=${limit}`
    );
    // const musics = { data: data.items, meta: data.meta };
    console.log("----------------------musics");
    console.log(musics);
    setState({
      ...dataState,
      page: musics.meta.currentPage,
      total: musics.meta.totalItems,
      data: musics.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getMusics();
  }, [category]);

  const handleDeleteMusicPopUp = (rowData) => {
    setDeleteMusicPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteMusic = async () => {
    const music = deleteMusicPopUpDialog.rowData;
    await API.remove(`musics/${music.musicUniqueId}`);
    setDeleteMusicPopUpDialog(deleteDialogInitialState);
    getMusics(dataState.page, dataState.limit);
  };

  const handlePopUpClose = () => {
    setDeleteMusicPopUpDialog(deleteDialogInitialState);
  };

  const columns = [
    {
      name: "musicUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "title",
      label: "Title",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = dataState.data[dataIndex];
          if (data.iconUrl) {
            return (
              <Box sx={{ display: "flex" }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 28, height: 28, marginRight: "5px" }}
                  src={data.iconUrl}
                />
                <Typography sx={{ lineHeight: "28px", fontSize: "0.875rem" }}>
                  {data.title}
                </Typography>
              </Box>
            );
          }

          return (
            <Typography sx={{ lineHeight: "28px", fontSize: "0.875rem" }}>
              {data.title}
            </Typography>
          );
        },

        filter: true,
        sort: true,
      },
    },
    {
      name: "media",
      label: "Audio",
      options: {
        customBodyRender: (value) => {
          console.log(value);
          if (value) return <audio src={value} controls />;
        },
        filter: true,
        sort: true,
      },
    },

    {
      name: "banner",
      label: "Banner",
      options: {
        customBodyRender: (value) => {
          if (value) return <img className="img" src={value} alt={"logo"} />;
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "isShowOnPiaScreenDefault",
      label: "Default pia music?",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton aria-label="default">
              {value ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            </IconButton>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "isPremium",
      label: "Premium?",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton aria-label="premium">
              {value ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            </IconButton>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "artist",
      label: "Artist",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "musicUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = dataState.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteMusicPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/musics/${data.musicUniqueId}`, {
                    state: { music: data, category, subcategoryId },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: dataState.limit,
    search: false,
    count: dataState.total,
    ...datatableRowOptions,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        getMusics(tableState.page + 1, dataState.limit);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddMusic = () => {
    console.log(category);
    navigate(`../../admin/musics`, {
      state: { category, subcategoryId },
    });
  };

  const navigateCategoriesPage = () => {
    navigate(`/admin/category/${category.categoryUniqueId}`);
  };

  const pageContent = (
    <div className="App wrapper">
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledButton
          label="Back"
          onClick={navigateCategoriesPage}
          startIcon={<ArrowBackIos />}
        />
        <StyledButton
          label="Add Meditation"
          onClick={navigateAddMusic}
          endIcon={<LibraryAdd />}
        />
      </Box>

      <MUIDataTable data={dataState.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteMusicPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteMusic}
        title="Delete music?"
        body="Are you sure you want to delete the music permanently?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Musics" />;
};

export default Meditation;
