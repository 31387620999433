import styled from "@emotion/styled";
import {
  inputLabelClasses,
  outlinedInputClasses,
  TextField,
} from "@mui/material";

export const StyledTextField = styled(TextField)({
  [`& .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: "#7447FF",
  },
  [`&:hover .${outlinedInputClasses.root} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: "#7447FF",
    },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline}`]:
    {
      borderColor: "#7447FF",
    },
  [`& .${outlinedInputClasses.input}`]: {
    color: "#7447FF",
  },
  [`&:hover .${outlinedInputClasses.input}`]: {
    color: "#7447FF",
  },
  [`& .${outlinedInputClasses.root}.${outlinedInputClasses.focused} .${outlinedInputClasses.input}`]:
    {
      // color: "#7447FF",
    },
  [`& .${inputLabelClasses.outlined}`]: {
    // color: "#7447FF",
  },
  [`&:hover .${inputLabelClasses.outlined}`]: {
    color: "#7447FF",
  },
  [`& .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    color: "#7447FF",
  },
});
