import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
import StyledButton from "../../components/StyledButton";
import { datatableRowOptions } from "../../config/constant";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";

const initialState = {
  page: 0,
  total: 0,
  limit: 5,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Banner = () => {
  const [state, setState] = useState(initialState);

  const getBanners = async (page = 1, limit = state.limit) => {
    const banners = await API.get(`banners?page=${page}&limit=${limit}`);

    await setState({
      ...state,
      isLoading: true,
    });

    await setState({
      ...state,
      limit,
      page: banners.meta.currentPage,
      total: banners.meta.totalItems,
      data: banners.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getBanners();
  }, []);

  const dialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteBannerPopUpDialog, setDeleteBannerPopUpDialog] =
    useState(dialogInitialState);

  const handleDeleteBannerPopUp = (rowData) => {
    setDeleteBannerPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteBanner = async () => {
    const banner = deleteBannerPopUpDialog.rowData;
    await API.remove(`banners/${banner.bannerUniqueId}`);
    setDeleteBannerPopUpDialog(dialogInitialState);
    getBanners(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteBannerPopUpDialog(dialogInitialState);
  };

  const columns = [
    {
      name: "bannerUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Banner",
      options: {
        customBodyRender: (value) => {
          if (value) return <img src={value} width={180} alt={"logo"} />;
        },
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "bannerFor",
    //   label: "Banner For",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "bannerType",
      label: "Banner Type",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "category",
      label: "Redirect To Screen",
      options: {
        customBodyRender: (value) => {
          return value ? `${value.name} Screen` : "-";
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "url",
      label: "URL",
      options: {
        customBodyRender: (value) => {
          return value ? value : "-";
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? moment(new Date(value)).format("D MMM YYYY") : "";
        },
        filter: true,
        sort: false,
      },
    },
    {
      name: "bannerUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteBannerPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/banners/${data.bannerUniqueId}`)
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      if (action === "changePage") {
        await getBanners(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getBanners(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddBanner = () => {
    navigate("../../admin/banner");
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton label="Add Banner" onClick={navigateAddBanner} />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteBannerPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteBanner}
        title="Delete banner?"
        body="Are you sure, you want to delete banner permanently?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Features / Banners" />;
};

export default Banner;
