import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { StyledSwitch } from "../../components/StyledSwitch";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";

import { Delete, Edit, LibraryAdd } from "@mui/icons-material";
import { datatableRowOptions } from "../../config/constant";
import DeleteDialog from "../../components/DeleteDialog";
import StyledButton from "../../components/StyledButton";

const dialogInitialState = {
  showHomeScreenPopUpOpen: false,
  popUpButtonText: "Show",
  dataIndex: 0,
  subcategoryUniqueId: "",
  isShowOnHomeScreen: "",
};

const initialState = {
  page: 0,
  total: 0,
  limit: 15,
  data: [["Loading Data..."]],
  isLoading: false,
};

const MainCategory = (props) => {
  const { category } = props;

  const [state, setState] = useState(initialState);

  const [dialogState, setDialogState] = useState(dialogInitialState);

  const deleteDialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteSubcategoryPopUpDialog, setDeleteSubcategoryPopUpDialog] =
    useState(deleteDialogInitialState);

  const handleShowHomeScreenPopUpOpen = (
    subcategoryUniqueId,
    isShowOnHomeScreen,
    dataIndex
  ) => {
    setDialogState({
      showHomeScreenPopUpOpen: true,
      popUpButtonText: isShowOnHomeScreen ? "Hide" : "Show",
      dataIndex,
      subcategoryUniqueId,
      isShowOnHomeScreen,
    });
  };

  const handleShowHomeScreenPopUpClose = () => {
    setDialogState(dialogInitialState);
  };

  const showHomeScreenUpdate = async () => {
    try {
      await API.post(`subcategories/${dialogState.subcategoryUniqueId}`, {
        isShowOnHomeScreen: !dialogState.isShowOnHomeScreen ? "true" : "false",
      });

      state.data[dialogState.dataIndex].isShowOnHomeScreen =
        !dialogState.isShowOnHomeScreen;

      setDialogState(dialogInitialState);
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

  const getSubCategories = async (page = 1, limit = state.limit) => {
    setState({
      ...state,
      isLoading: true,
    });

    const subcategories = await API.get(
      `subcategories?categoryId=${category.id}&page=${page}&limit=${limit}`
    );
    setState({
      ...state,
      limit,
      page: subcategories.meta.currentPage,
      total: subcategories.meta.totalItems,
      data: subcategories.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getSubCategories();
  }, [category]);

  const handleDeleteSubcategoryPopUp = (rowData) => {
    setDeleteSubcategoryPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteSubcategory = async () => {
    const subcategory = deleteSubcategoryPopUpDialog.rowData;
    await API.remove(`subcategories/${subcategory.subcategoryUniqueId}`);
    setDeleteSubcategoryPopUpDialog(deleteDialogInitialState);
    getSubCategories(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteSubcategoryPopUpDialog(deleteDialogInitialState);
  };

  const columns = [
    {
      name: "subcategoryUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];
          if (data.iconUrl) {
            return (
              <Box sx={{ display: "flex" }}>
                <Avatar
                  alt="Remy Sharp"
                  sx={{ width: 22, height: 22, marginRight: "5px" }}
                  src={data.iconUrl}
                />
                <Typography sx={{ lineHeight: "28px", fontSize: "0.875rem" }}>
                  {data.name}
                </Typography>
              </Box>
            );
          }

          return (
            <Typography sx={{ lineHeight: "28px", fontSize: "0.875rem" }}>
              {data.name}
            </Typography>
          );
        },

        filter: true,
        sort: true,
      },
    },
    {
      name: "isShowOnHomeScreen",
      label: "Show on Home",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <StyledSwitch
              onChange={() =>
                handleShowHomeScreenPopUpOpen(
                  data.subcategoryUniqueId,
                  !!data.isShowOnHomeScreen,
                  dataIndex
                )
              }
              checked={!!data.isShowOnHomeScreen}
            />
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "subcategoryUniqueId",
      label: "Add Musics",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Button
              onClick={() =>
                navigate(`/admin/musics/${category.type}`, {
                  state: { category, subcategoryId: data.subcategoryId },
                })
              }
              size="small"
              variant="outlined"
              sx={{ textTransform: "capitalize", fontSize: "0.875rem" }}
              autoFocus
            >
              Manage Musics
            </Button>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "subcategoryUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteSubcategoryPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/subcategories/${data.subcategoryUniqueId}`)
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   getSubCategories(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getSubCategories(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getSubCategories(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddSubcategory = () => {
    navigate(
      `../../admin/category/${category.categoryUniqueId}/subcategories`,
      {
        state: { category },
      }
    );
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton
          label="Add Subcategory"
          onClick={navigateAddSubcategory}
          endIcon={<LibraryAdd />}
        />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <Dialog
        open={dialogState.showHomeScreenPopUpOpen}
        onClose={handleShowHomeScreenPopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Show On the Home Screen
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure, you want to {dialogState.popUpButtonText} this
            category in the home screen?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleShowHomeScreenPopUpClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={showHomeScreenUpdate} autoFocus>
            {dialogState.popUpButtonText}
          </Button>
        </DialogActions>
      </Dialog>

      <DeleteDialog
        open={deleteSubcategoryPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteSubcategory}
        title="Delete subcategory?"
        body="All the music in the selected category will be permanently deleted.
        Are you sure you want to delete the subcategory permanently?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle={category.name} />;
};

export default MainCategory;
