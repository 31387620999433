import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledSwitch } from "../../../components/StyledSwitch";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import { CategoriesEnum } from "../../../config/enums";
import ButtonCircularProgress from "../../../components/buttonLoader";

const EditSubcategory = () => {
  const { subcategoryUniqueId } = useParams();

  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [type, setType] = useState("");
  const [categoryType, setCategoryType] = useState("");

  const [iconUrl, setIconUrl] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isShowOnHomeScreen, setIsShowOnHomeScreen] = useState(false);
  const [buttonText, setButtonText] = useState("Save");
  const [subcategory, setSubcategory] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getSubcategoryDetail = async (subcategoryUniqueId) => {
      const data = await API.get(`subcategories/${subcategoryUniqueId}`);
      console.log(data);
      setSubcategory(data);

      setIconUrl(data.iconUrl);
      setIsShowOnHomeScreen(data.isShowOnHomeScreen);
      setType(data.type);
      setName(data.name);
      setCategoryId(data.category.categoryId);
      setCategoryType(data.category.type);
    };

    getSubcategoryDetail(subcategoryUniqueId);
  }, [subcategoryUniqueId]);

  const navigateCategoriesPage = () => {
    navigate(`/admin/category/${subcategory.category.categoryUniqueId}`);
  };

  const editSubcategory = async () => {
    if (!name) {
      toast.error("Please enter subcategory name.");
      return;
    }

    let formData = new FormData();

    formData.append("name", name);
    formData.append("isShowOnHomeScreen", isShowOnHomeScreen);
    formData.append("icon", icon);
    console.log(icon);
    formData.append("type", type);
    console.log(subcategory.category);
    formData.append("categoryId", categoryId);

    for (const pair of formData.entries()) {
      console.log(`${pair[0]}, ${pair[1]}`);
    }

    setButtonText("Saving...");
    setIsLoading(true);

    await API.post(`subcategories/${subcategoryUniqueId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setIsLoading(false);
    setButtonText("Save");
    navigateCategoriesPage();
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        edit subcategory to main category
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            {/* Icon upload */}
            {[
              CategoriesEnum.MUSIC,
              CategoriesEnum.MEDITATION,
              CategoriesEnum.SOUNDSCAPE,
            ].includes(categoryType) && (
              <Box>
                <Typography mb={1} sx={{ color: "gray" }}>
                  Upload icon file:
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                  NOTE: Icon will show when it will show on App Home screen.
                  Upload an icon with a 1:1 aspect ratio (It is preferable to
                  use 72*72px.). 
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  endIcon={<CloudUploadIcon />}
                >
                  Icon
                  <input
                    onChange={(e) => setIcon(e.target.files[0])}
                    type="file"
                    accept="image/*"
                    hidden
                    name="icon"
                  />
                </Button>
                <br />
                {iconUrl && (
                  <Box mt={2}>
                    <img
                      src={subcategory.iconUrl}
                      sx={{ paddingTop: "2px" }}
                      width={70}
                      alt={"logo"}
                    />
                  </Box>
                )}
              </Box>
            )}

            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            {/* Subcategory name */}
            <StyledTextField
              label="Name"
              value={name}
              variant="outlined"
              fullWidth
              placeholder="Healing Music"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            {[
              CategoriesEnum.MUSIC,
              CategoriesEnum.MEDITATION,
              CategoriesEnum.SOUNDSCAPE,
            ].includes(categoryType) && (
              <Box mt={2} sx={{ display: "flex" }}>
                <Typography sx={{ color: "gray" }}>
                  Do you want to see subcategories on the app's home screen? 
                </Typography>

                <StyledSwitch
                  checked={isShowOnHomeScreen}
                  onChange={(e) => setIsShowOnHomeScreen(!isShowOnHomeScreen)}
                />
                <br />
              </Box>
            )}

            {/* <StyledTextField
              fullWidth
              variant="outlined"
              value={type}
              label="Select type"
              onChange={(e) => setType(e.target.value)}
              select
            >
              <MenuItem key="music" value="music">
                Music list
              </MenuItem>
              <MenuItem key="mix" value="mix">
                Music Mix
              </MenuItem>
            </StyledTextField>
            <Typography variant="caption" display="block" gutterBottom>
              Music list: It allows you to add single music for a given
              subcategory. 
              <br />
              Mix: It allows you to make a mix with up to four musics. 
            </Typography> */}

            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={editSubcategory}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateCategoriesPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Edit Subcategory" />;
};

export default EditSubcategory;
