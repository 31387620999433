import styled from "@emotion/styled";
import { Box, LinearProgress } from "@mui/material";
import React from "react";

const StyledLinearProgress = styled(LinearProgress)({
  root: {
    "& .MuiLinearProgress-colorPrimary": {
      backgroundColor: "red",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "green",
    },
  },
});

const Loader = () => {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    // <Stack
    //   sx={{
    //     height: "100vh",
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //   }}
    // >
    //   <img src={logo} alt="logo" width="100" />
    //   <Typography sx={{ mt: 2 }}>Loading...</Typography>
    // </Stack>
    <Box sx={{ width: "100%" }}>
      <StyledLinearProgress variant="determinate" value={progress} />
    </Box>
  );
};

export default Loader;
