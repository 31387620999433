import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import ButtonCircularProgress from "../../../components/buttonLoader";

const AddPrecautions = () => {
  const navigate = useNavigate();
  const navigatePrecautionPage = () => {
    navigate("/admin/precautions", { replace: true });
  };

  const [name, setName] = useState("");
  const [image, setImage] = useState("");
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  const storePrecaution = async () => {
    if (!name) {
      toast.error("Please enter description.");
      return;
    }

    let formData = new FormData();

    formData.append("image", image);
    formData.append("name", name);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post("precautions", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setIsLoading(false);
    setButtonText("Save");

    if (res.message && !res.statusCode) {
      navigatePrecautionPage();
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Upload precautions. 
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Precaution Description"
              variant="outlined"
              fullWidth
              placeholder="Add Precaution Description"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            {/* Image upload */}
            <Typography mb={1} mt={1} sx={{ color: "gray" }}>
              Upload Icon:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              NOTE: Upload a image with a width 25px and height 25px (It is
              preferable to use 25px * 25px.).
            </Typography>
            <Button
              variant="contained"
              component="label"
              endIcon={<CloudUploadIcon />}
            >
              Add Icon
              <input
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
                accept="image/*"
                hidden
                name="image"
              />
            </Button>
            {typeof image === "object" && (
              <Box
                sx={{
                  ml: 1,
                  display: "flex",
                  mt: 2,
                  bgcolor: "#000",
                  padding: "20px",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "fit-content",
                  borderRadius: "5px",
                }}
              >
                <img src={URL.createObjectURL(image)} alt="icon" width="24" />
              </Box>
            )}
            <br />
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storePrecaution}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigatePrecautionPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Add Sleep Precaution" />;
};

export default AddPrecautions;
