import { Button, Card, MenuItem, Select, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api"; //../../services/Api
import ButtonCircularProgress from "../../../components/buttonLoader";
import moment from "moment";
import dayjs from "dayjs";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const EditEmail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const notification = state.notification;

  const navigateNotificationPage = () => {
    navigate("/admin/push-emails");
  };

  const getNotificationOption = async () => {
    const data = await API.get("notification-repeat-options");
    setNotificationRepeatOption(data);
  };

  const notificationScheduleLabel = () => {
    if (!notification.scheduledAt || isNaN(notification.scheduledAt)) {
      console.error("Invalid or missing timestamp:", notification.scheduledAt);
      return ""; // or handle the error as needed
    }

    const date = new Date(notification.scheduledAt * 1000);

    if (isNaN(date.getTime())) {
      console.error("Invalid date:", date);
      return ""; // or handle the error as needed
    }

    const options = {
      timeZone: "UTC",
      year: "numeric",
      month: "short",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      // second: "2-digit",
      hour12: true,
    };
    const utcTime = date.toLocaleString("en-US", options);

    setScheduledAtLabel(utcTime);
  };

  const [groupType, setGroupType] = React.useState(notification.groupType.id);
  const [body, setBody] = useState(notification.body);
  const [title, setTitle] = useState(notification.title);
  const [scheduledAtLabel, setScheduledAtLabel] = useState();

  // const [redirectionScreenType, setRedirectionScreenType] = useState(
  //   notification.redirectionScreenType.id
  // );
  const [scheduledAt, setScheduledAt] =
    useState();
    // dayjs.unix(moment(notification.scheduledAt).local())
    // dayjs.unix(notification.scheduledAt).utc()
  const [buttonText, setButtonText] = useState("Schedule");
  const [userGroups, setUserGroups] = useState([]);
  const [notificationRedirections, setNotificationRedirectionss] = useState([]);
  const [notificationRepeat, setNotificationRepeat] = useState(
    notification.notificationFrequencyOptions
  );
  const [notificationRepeatOption, setNotificationRepeatOption] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const scheduleNotification = async () => {
    if (!groupType) {
      toast.error("Please select user groups.");
      return;
    }

    if (!scheduledAt) {
      toast.error("Please select notification schedule.");
      return;
    }

    if (!body) {
      toast.error("Please enter notification body.");
      return;
    }

    if (!title) {
      toast.error("Please enter notification title.");
      return;
    }

    // if (!redirectionScreenType) {
    //   toast.error("Please select redirect Url.");
    //   return;
    // }
    setButtonText("Scheduling...");
    setIsLoading(true);

    const timestamp = new Date(scheduledAt.$d);

    // Extract the date and time components
    const year = timestamp.getFullYear();
    const month = String(timestamp.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(timestamp.getDate()).padStart(2, "0");
    const hours = String(timestamp.getHours()).padStart(2, "0");
    const minutes = String(timestamp.getMinutes()).padStart(2, "0");
    const seconds = String(timestamp.getSeconds()).padStart(2, "0");

    // Create the formatted date-time string
    const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    console.log(formattedDateTime);

    const res = await API.put(
      `notification/${notification.id}`,
      {
        groupType,
        body,
        title,
        // scheduledAt: moment(scheduledAt.$d).utc().unix(),
        // scheduledAt: dayjs(scheduledAt.$d).utc().unix(),
        scheduledAt: moment
          .utc(formattedDateTime, "YYYY-MM-DD HH:mm:ss")
          .unix(),
        notificationType: "email",
        notificationFrequencyOptions: notificationRepeat,
        redirectionScreenType: null,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    setIsLoading(false);
    setButtonText("Schedule");

    navigateNotificationPage();
  };

  const getNotificationConstants = async () => {
    const data = await API.get(`notification-constants?groupType=${groupType}`);
    setUserGroups(data.notificationUserGroups);
    setNotificationRedirectionss(data.notificationRedirections);
  };

  const getUpdatedNotificationRedirections = async (e) => {
    setGroupType(e.target.value);
    console.log(e.target.value);
    const data = await API.get(
      `notification-constants?groupType=${e.target.value}`
    );
    setNotificationRedirectionss(data.notificationRedirections);
  };

  useEffect(() => {
    getNotificationConstants();
    getNotificationOption();
    notificationScheduleLabel();
  }, []);

  const pageContent = (
    <Box>
      <Card variant="outlined">
        <form className="form">
          {/* <Box m={3}>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Select user group"
              value={groupType}
              onChange={getUpdatedNotificationRedirections}
              select
            >
              {userGroups.map((u) => (
                <MenuItem key={u.id} value={u.id}>
                  {u.text}
                </MenuItem>
              ))}
            </StyledTextField>

            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <MobileDateTimePicker
                  slotProps={{
                    textField: {
                      variant: "outlined",
                    },
                  }}
                  label="Notification Schedule"
                  minutesStep={5}
                  value={scheduledAt}
                  onChange={(e) => {
                    console.log("change---");
                    console.log(e);
                    setScheduledAt(e);
                  }}
                  sx={{
                    label: {
                      color: "#637381 !important",
                    },
                    width: "100%",
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            
            <StyledTextField
              label="Notification Description"
              variant="outlined"
              fullWidth
              value={body}
              placeholder="Notification Description"
              type="text"
              onChange={(e) => setBody(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Notification Title"
              variant="outlined"
              fullWidth
              value={title}
              placeholder="Notification Title"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />

            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Select Redirection Screen"
              value={redirectionScreenType}
              onChange={(e) => setRedirectionScreenType(e.target.value)}
              select
            >
              {notificationRedirections.map((n) => (
                <MenuItem key={n.id} value={n.id}>
                  {n.text}
                </MenuItem>
              ))}
            </StyledTextField>

            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={scheduleNotification}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateNotificationPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box> */}
          <Box m={3}>
            <StyledTextField
              fullWidth
              variant="outlined"
              label="Select user group"
              value={groupType}
              onChange={getUpdatedNotificationRedirections}
              select
            >
              {userGroups.map((u) => (
                <MenuItem key={u.id} value={u.id}>
                  {u.text}
                </MenuItem>
              ))}
            </StyledTextField>

            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <Typography mt={2} sx={{ color: "black" }}>
              Email Notification Schedule : {scheduledAtLabel}
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <MobileDateTimePicker
                  slotProps={{
                    textField: {
                      variant: "outlined",
                    },
                  }}
                  // label="Email Notification Schedule"
                  minutesStep={5}
                  value={scheduledAt}
                  onChange={(e) => {
                    setScheduledAt(e);
                  }}
                  sx={{
                    label: {
                      color: "#637381 !important",
                    },
                    width: "100%",
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>

            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Email Subject"
              variant="outlined"
              fullWidth
              value={title}
              placeholder="Email Subject"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Email Body"
              variant="outlined"
              fullWidth
              value={body}
              placeholder="Email Body"
              type="text"
              onChange={(e) => setBody(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            <StyledTextField
              fullWidth
              variant="outlined"
              label="Notification Repeat Schedule"
              value={notificationRepeat}
              onChange={(e) => setNotificationRepeat(e.target.value)}
              select
            >
              {notificationRepeatOption.map((n) => (
                <MenuItem key={n.value} value={n.value}>
                  {n.text}
                </MenuItem>
              ))}
            </StyledTextField>
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={scheduleNotification}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateNotificationPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Edit Notification" />;
};

export default EditEmail;
