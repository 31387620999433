import {
  Button,
  Card,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import "../Banner.scss";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import ButtonCircularProgress from "../../../components/buttonLoader";

const AddBanner = () => {
  const navigate = useNavigate();
  const navigateBannersPage = () => {
    navigate("/admin/banners");
  };

  const [bannerType, setBannerType] = useState("");
  const [bannerFor, setBannerFor] = useState("");
  const [redirectTo, setRedirectTo] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [image, setImage] = useState("");
  const [url, setUrl] = useState("");
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getBannerRedirectionScreensForApp = async () => {
      const data = await API.get("categories");
      setCategory(data);
    };

    getBannerRedirectionScreensForApp();
  }, []);

  const storeBanner = async () => {
    if (!bannerType) {
      toast.error("Please select banner type.");
      return;
    }

    // if (!bannerFor) {
    //   toast.error("Please select banner for.");
    //   return;
    // }

    if (!redirectTo) {
      toast.error("Please select where you redirect.");
      return;
    }

    if (redirectTo === "category" && !categoryId) {
      toast.error("Please select category");
      return;
    }
    if (redirectTo === "url" && !url) {
      toast.error("Url field is required");
      return;
    }

    let formData = new FormData();

    formData.append("image", image);
    // formData.append("bannerFor", bannerFor);
    formData.append("bannerType", bannerType);
    formData.append("categoryId", categoryId);
    formData.append("url", url);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post("banners", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setIsLoading(false);
    setButtonText("Save");

    if (res.message && !res.statusCode) {
      navigateBannersPage();
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Upload a banner from here to have it appear on the app home screen. 
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            {/* Banner upload */}
            <Typography mb={1} sx={{ color: "gray" }}>
              Upload banner file:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              NOTE: Upload a banner with a width 670px and height 232px (It is
              preferable to use 670px * 232px.). 
            </Typography>
            <Button
              variant="contained"
              component="label"
              endIcon={<CloudUploadIcon />}
            >
              Upload Banner
              <input
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
                accept="image/*"
                hidden
                name="image"
              />
            </Button>
            <br />

            {/* Banner type */}
            <Typography mt={1} sx={{ color: "gray" }}>
              Banner Type:
            </Typography>
            <RadioGroup
              row
              onChange={(e) => setBannerType(e.target.value)}
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            >
              <FormControlLabel
                value="offer"
                control={<Radio />}
                label="Offer Banner"
              />
              <FormControlLabel
                value="normal"
                control={<Radio />}
                label="Normal Banner"
              />
            </RadioGroup>

            {/* Banner For */}
            {/* <Typography mt={1} sx={{ color: "gray" }}>
              Banner For:
            </Typography>
            <RadioGroup
              row
              onChange={(e) => setBannerFor(e.target.value)}
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="free-users"
                control={<Radio />}
                label="Free Users"
              />
              <FormControlLabel
                value="paid-users"
                control={<Radio />}
                label="Paid Users"
              />

              <FormControlLabel value="both" control={<Radio />} label="Both" />
            </RadioGroup> */}
            {/* Web page URL */}
            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            {/* Redirection */}
            <Typography mt={1} sx={{ color: "gray" }}>
              Redirect to:
            </Typography>

            <RadioGroup
              row
              onChange={(e) => {
                setRedirectTo(e.target.value);
                setUrl("");
                categoryId("");
              }}
              name="row-radio-buttons-group"
            >
              <FormControlLabel
                value="category"
                control={<Radio />}
                label="Category"
              />
              <FormControlLabel value="url" control={<Radio />} label="URL" />
            </RadioGroup>

            {redirectTo === "category" && (
              <StyledTextField
                fullWidth
                variant="outlined"
                label="Select category"
                onChange={(e) => setCategoryId(e.target.value)}
                select
              >
                {category.map((c) => (
                  <MenuItem key={c.categoryId} value={c.categoryId}>
                    {c.name}
                  </MenuItem>
                ))}
              </StyledTextField>
            )}

            {redirectTo === "url" && (
              <StyledTextField
                label="Url"
                variant="outlined"
                fullWidth
                placeholder="Banner Url"
                type="url"
                onChange={(e) => setUrl(e.target.value)}
              />
            )}
            <br />
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storeBanner}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateBannersPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Features/ Add Banner" />;
};

export default AddBanner;
