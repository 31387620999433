import { Delete, Edit, Image } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { Box } from "@mui/system";
import moment from "moment";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteDialog from "../../components/DeleteDialog";
import StyledButton from "../../components/StyledButton";
import { datatableRowOptions } from "../../config/constant";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";

const initialState = {
  page: 0,
  total: 0,
  limit: 5,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Precautions = () => {
  const [state, setState] = useState(initialState);

  const getPrecautions = async (page = 1, limit = state.limit) => {
    const precautions = await API.get(
      `precautions?page=${page}&limit=${limit}`,
      {}
    );
    setState({
      ...state,
      isLoading: true,
    });

    setState({
      ...state,
      limit,
      page: precautions.meta.currentPage,
      total: precautions.meta.totalItems,
      data: precautions.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getPrecautions();
  }, []);

  const dialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deletePrecautionsPopUpDialog, setDeletePrecautionsPopUpDialog] =
    useState(dialogInitialState);

  const [viewArticleDialog, setViewArticleDialog] = useState({
    isOpen: false,
    rowData: null,
  });

  const handleDeletePrecautionsPopUp = (rowData) => {
    setDeletePrecautionsPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deletePrecautions = async () => {
    const precautions = deletePrecautionsPopUpDialog.rowData;
    await API.remove(`precautions/${precautions.precautionUniqueId}`);
    setDeletePrecautionsPopUpDialog(dialogInitialState);
    getPrecautions(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeletePrecautionsPopUpDialog(dialogInitialState);
    setViewArticleDialog({ isOpen: false, rowData: null });
  };

  const columns = [
    {
      name: "precautionUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Icon",
      options: {
        customBodyRender: (value) => {
          if (value)
            return (
              <Box
                sx={{
                  bgcolor: "#000",
                  padding: "20px",
                  borderRadius: "5px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img src={value} width={25} alt={"logo"} />
              </Box>
            );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Description",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        customBodyRender: (value) => {
          return value ? moment(new Date(value)).format("D MMM YYYY") : "";
        },
        filter: true,
        sort: false,
      },
    },
    {
      name: "precautionUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeletePrecautionsPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/precautions/${data.precautionUniqueId}`, {
                    state: { precautions: data },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   getPrecautions(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getPrecautions(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getPrecautions(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddPrecaution = () => {
    navigate("../../admin/add-precautions");
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton label="Add Precautions" onClick={navigateAddPrecaution} />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <DeleteDialog
        open={deletePrecautionsPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deletePrecautions}
        title="Delete Precaution?"
        body="Are you sure, you want to delete precaution permanently?"
      />

      <Dialog
        open={viewArticleDialog.isOpen}
        onClose={handlePopUpClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {viewArticleDialog.rowData?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {/* <ImageListItem
              key={viewArticleDialog.rowData?.banner}
              width="120px"
            >
              <img
                width="120px"
                src={`${viewArticleDialog.rowData?.banner}`}
                loading="lazy"
                alt="test"
              />
              <ImageListItemBar
                title={viewArticleDialog.rowData?.title}
                subtitle={viewArticleDialog.rowData?.subtitle}
              />
            </ImageListItem> */}
            <div
              dangerouslySetInnerHTML={{
                __html: viewArticleDialog.rowData?.description,
              }}
            ></div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handlePopUpClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Precaution" />;
};

export default Precautions;
