import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import ButtonCircularProgress from "../../../components/buttonLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddArticle = () => {
  const navigate = useNavigate();
  const navigateArticlesPage = () => {
    navigate("/admin/articles");
  };

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [description, setDescription] = useState("");
  const [banner, setBanner] = useState("");
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  const storeArticle = async () => {
    console.log(description);
    if (!title) {
      toast.error("Please enter title.");
      return;
    }

    if (!subtitle) {
      toast.error("Please enter subtitle.");
      return;
    }

    console.log(description);
    if (!description) {
      toast.error("Please enter description");
      return;
    }

    let formData = new FormData();

    formData.append("banner", banner);
    formData.append("title", title);
    formData.append("subtitle", subtitle);
    formData.append("description", description);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post("articles", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setIsLoading(false);
    setButtonText("Save");

    if (res.message && !res.statusCode) {
      navigateArticlesPage();
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Upload a banner for article. 
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Article title"
              variant="outlined"
              fullWidth
              placeholder="Why Do We Need Sleep?"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Article subtitle"
              variant="outlined"
              fullWidth
              placeholder="Sleep is an essential function"
              type="text"
              onChange={(e) => setSubtitle(e.target.value)}
            />
            <br />
            {/* Banner upload */}
            <Typography mb={1} mt={1} sx={{ color: "gray" }}>
              Upload banner file:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              NOTE: Upload a banner with a width 335px and height 116px (It is
              preferable to use 335px * 116px.). 
            </Typography>
            <Button
              variant="contained"
              component="label"
              endIcon={<CloudUploadIcon />}
            >
              Upload Banner
              <input
                onChange={(e) => setBanner(e.target.files[0])}
                type="file"
                accept="image/*"
                hidden
                name="banner"
              />
            </Button>
            <br />
            <Typography mb={1} mt={1} sx={{ color: "gray" }}>
              Add Article description:
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              data="<p>Start Typing...</p>"
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                ],
              }}
              onReady={(editor) => {
                console.log(editor);
              }}
              onChange={(event, editor) => {
                setDescription(editor.getData());
                console.log(editor.getData());
                console.log("console.log(editor.getData());");
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
            <br />
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storeArticle}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateArticlesPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Add Sleep Article" />;
};

export default AddArticle;
