import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";
import MainCategory from "./CommonCategory";
import WhiteNoise from "./WhiteNoise/WhiteNoise";
import SleepStory from "./SleepStory/SleepStory";
import { CategoriesEnum } from "../../config/enums";
import SoundScape from "./SoundScape/SoundScape";

const pageContent = <div></div>;

const Category = () => {
  const { categoryUniqueId } = useParams();

  const [categoryDetail, setCategoryDetail] = useState({});

  useEffect(() => {
    const getCategoryDetails = async (params) => {
      const category = await API.get(`categories/${categoryUniqueId}`);
      setCategoryDetail(category);
    };
    getCategoryDetails(categoryUniqueId);
  }, [categoryUniqueId]);

  if (categoryDetail.type === CategoriesEnum.WHITE_NOISE) {
    return <WhiteNoise category={categoryDetail} />;
  }

  if (categoryDetail.type === CategoriesEnum.MUSIC) {
    return <MainCategory category={categoryDetail} />;
  }

  if (categoryDetail.type === CategoriesEnum.MEDITATION) {
    return <MainCategory category={categoryDetail} />;
  }

  if (categoryDetail.type === CategoriesEnum.SLEEP_STORY) {
    return <SleepStory category={categoryDetail} />;
  }

  if (categoryDetail.type === CategoriesEnum.SOUNDSCAPE) {
    return <SoundScape category={categoryDetail} />;
  }

  return <SideBar pageContent={pageContent} pageTitle={categoryDetail.name} />;
};

export default Category;
