import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as API from "../../../../services/Api";
import {
  CheckBoxOutlineBlankOutlined,
  ArrowBackIos,
  CheckBoxOutlined,
  Delete,
  Edit,
  LibraryAdd,
} from "@mui/icons-material";
import SideBar from "../../../../layouts/Sidebar";
import DeleteDialog from "../../../../components/DeleteDialog";
import StyledButton from "../../../../components/StyledButton";
import { datatableRowOptions } from "../../../../config/constant";

const initialState = {
  page: 0,
  total: 0,
  limit: 15,
  data: [["Loading Data..."]],
  isLoading: false,
};

const Mixes = (props) => {
  const { state } = useLocation();

  const category = state.category;

  console.log("category", category);
  const [dataState, setState] = useState(initialState);

  const deleteDialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteDefaultMixPopUpDialog, setDeleteDefaultMixPopUpDialog] =
    useState(deleteDialogInitialState);

  const getDefaultMixes = async (page = 1, limit = dataState.limit) => {
    setState({
      ...dataState,
      isLoading: true,
    });

    const mixes = await API.get(
      `musics/default-mixes?page=${page}&limit=${limit}`
    );

    setState({
      ...dataState,
      page: mixes.meta.currentPage,
      total: mixes.meta.totalItems,
      data: mixes.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getDefaultMixes();
  }, []);

  const handleDeleteDefaultMixPopUp = (rowData) => {
    setDeleteDefaultMixPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteDefaultMix = async () => {
    const mix = deleteDefaultMixPopUpDialog.rowData;
    await API.remove(`mixes/${mix.mixUniqueId}`);
    setDeleteDefaultMixPopUpDialog(deleteDialogInitialState);
    getDefaultMixes(dataState.page, dataState.limit);
  };

  const handlePopUpClose = () => {
    setDeleteDefaultMixPopUpDialog(deleteDialogInitialState);
  };

  const columns = [
    {
      name: "mixUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = dataState.data[dataIndex];

          const colors = ["#C66767", "#81B7F3", "#81B4B9", "#8A67F6"];
          /* <img className="white-noise-img" src={value} alt={"logo"} /> */
          return (
            <Box p={1}>
              {data.musicMixes?.map((child, key) => (
                <Box
                  component="img"
                  className="white-noise-img-mix"
                  sx={{ backgroundColor: colors[key] }}
                  src={child.banner}
                  alt={"logo"}
                />
              ))}
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "isPremium",
      label: "Premium",
      options: {
        customBodyRender: (value) => {
          return (
            <IconButton aria-label="premium">
              {value ? <CheckBoxOutlined /> : <CheckBoxOutlineBlankOutlined />}
            </IconButton>
          );
        },
        filter: false,
        sort: true,
      },
    },
    {
      name: "mixUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = dataState.data[dataIndex];

          return (
            <Box>
              <IconButton
                onClick={() => handleDeleteDefaultMixPopUp(data)}
                aria-label="delete"
                sx={{ color: "#d11a2a" }}
              >
                <Delete />
              </IconButton>
              <IconButton
                onClick={() =>
                  navigate(`/admin/mixes/${data.mixUniqueId}`, {
                    state: { mix: data, category },
                  })
                }
                aria-label="edit"
                color="primary"
              >
                <Edit />
              </IconButton>
            </Box>
          );
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: dataState.limit,
    search: false,
    count: dataState.total,
    ...datatableRowOptions,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        getDefaultMixes(tableState.page + 1, dataState.limit);
      }
    },
  };

  const navigate = useNavigate();

  const navigateCategoriesPage = () => {
    navigate(`/admin/category/${category.categoryUniqueId}`);
  };

  const navigateAddDefaultMix = () => {
    navigate(`../../admin/musics/mixes`, {
      state: { category },
    });
  };

  const pageContent = (
    <div className="App wrapper">
      <Box
        component="span"
        m={1}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StyledButton
          label="Back"
          onClick={navigateCategoriesPage}
          startIcon={<ArrowBackIos />}
        />
        <StyledButton
          label="Add Mix"
          onClick={navigateAddDefaultMix}
          endIcon={<LibraryAdd />}
        />
      </Box>

      <MUIDataTable data={dataState.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteDefaultMixPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteDefaultMix}
        title="Delete mix?"
        body="Are you sure you want to delete mix?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle="Sound Scenes" />;
};

export default Mixes;
