import {
  Autocomplete,
  Button,
  Card,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../../layouts/Sidebar";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as API from "../../../../services/Api";
import { StyledTextField } from "../../../../components/StyledTextField";
import ButtonCircularProgress from "../../../../components/buttonLoader";
import { Delete } from "@mui/icons-material";
import { StyledSwitch } from "../../../../components/StyledSwitch";

const EditMix = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const mix = state.mix;
  console.log(mix);
  const category = state.category;
  const [isPremium, setIsPremium] = useState(mix.isPremium);
  const [title, setTitle] = useState(mix.name);
  const [buttonText, setButtonText] = useState("Save");
  const [isLoading, setIsLoading] = useState(false);

  const [whiteNoiseMusics, setWhiteNoiseMusics] = useState([]);

  const [selectedMixes, setSelectedMixes] = useState(mix.musicMixes);

  const navigateCategoriesPage = () => {
    navigate(`/admin/musics/white-noise/mixes`, {
      state: { category },
    });
  };

  const editMix = async () => {
    if (!title) {
      toast.error("Please enter mix name.");
      return;
    }

    let formData = new FormData();

    const musicUniqueIds = selectedMixes.map((m) => m.musicUniqueId);

    if (musicUniqueIds.length === 0)
      return toast.error("Please select musics.");
    formData.append("isPremium", isPremium);
    formData.append("name", title);
    formData.append("musicUniqueIds", musicUniqueIds);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post(`mixes/${mix.mixUniqueId}`, {
      name: title,
      musicUniqueIds,
      isPremium: `${isPremium}`,
    });

    console.log(res);
    setIsLoading(false);
    setButtonText("Save");
    if (res.message && !res.statusCode) {
      navigateCategoriesPage();
    }
  };

  const getAllWhiteNoiseMusics = async () => {
    const whiteNoiseMusics = await API.get(`musics/white-noise/all`);
    setWhiteNoiseMusics(whiteNoiseMusics);
  };

  const addToMixes = async (mix) => {
    if (selectedMixes.length >= 4)
      return toast.error("You can only add musics up to 4");

    const filter = selectedMixes
      .map((m) => m.musicId === mix.musicId)
      .filter((m) => m === true);

    if (filter.length) return toast.error("Music is already added.");

    setSelectedMixes([...selectedMixes, mix]);
  };

  const deleteMixItem = async (mix) => {
    const newMixes = selectedMixes.filter(
      (item) => item.musicId !== mix.musicId
    );

    setSelectedMixes(newMixes);
  };

  useEffect(() => {
    getAllWhiteNoiseMusics();
  }, []);

  const colors = ["#C66767", "#81B7F3", "#81B4B9", "#8A67F6"];

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Edit Mix
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            <Typography mt={2} sx={{ color: "gray" }}></Typography>

            <StyledTextField
              label="Mix name"
              variant="outlined"
              fullWidth
              value={title}
              placeholder="The story book"
              type="text"
              onChange={(e) => setTitle(e.target.value)}
            />
            <br />
            <br />

            <Autocomplete
              id="combo-box-demo"
              options={whiteNoiseMusics}
              getOptionLabel={(option) => option.title}
              onChange={(e, value) => addToMixes(value)}
              renderInput={(params) => (
                <TextField {...params} label="Add Musics (Up to 4)" />
              )}
            />

            <Box mt={2}>
              {selectedMixes?.map((child, key) => (
                <Box
                  component="img"
                  className="white-noise-img-mix"
                  sx={{ backgroundColor: colors[key] }}
                  src={child.banner}
                  alt={"logo"}
                />
              ))}
            </Box>
            <Box sx={{ display: "flex" }}>
              {selectedMixes?.map((child, key) => (
                <Box
                  sx={{
                    textAlign: "center",
                    display: "block",
                    width: "50px",
                    marginRight: "5px",
                    cursor: "pointer",
                  }}
                >
                  <Delete onClick={(e, value) => deleteMixItem(child)} />
                </Box>
              ))}
            </Box>
            <Box mt={2} sx={{ display: "flex" }}>
              <Typography sx={{ color: "gray" }}>Is mix premium? </Typography>

              <StyledSwitch
                checked={isPremium}
                onChange={(e) => setIsPremium(!isPremium)}
              />
              <br />
            </Box>
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={editMix}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateCategoriesPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Edit Mix" />;
};

export default EditMix;
