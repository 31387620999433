import { Box, Typography } from "@mui/material";
import variables from "../css/variables.scss";

const PageNotFound = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "white",
      }}
    >
      <Box>
        <Typography variant="h5" color={variables.baseColor}>
          404 | NOT FOUND
        </Typography>
      </Box>
    </Box>
  );
};

export default PageNotFound;
