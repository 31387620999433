import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import SideBar from "../../../layouts/Sidebar";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { StyledTextField } from "../../../components/StyledTextField";
import * as API from "../../../services/Api";
import ButtonCircularProgress from "../../../components/buttonLoader";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditDream = () => {
  const { state } = useLocation();
  const dream = state.dream;
  const navigate = useNavigate();
  const navigateDreamPage = () => {
    navigate("/admin/dream-analysis");
  };

  const [name, setName] = useState(dream.name);
  // const [url, setUrl] = useState(dream.url);
  const [description, setDescription] = useState(dream.description);
  const [image, setImage] = useState();
  const [buttonText, setButtonText] = useState("Save");

  const [isLoading, setIsLoading] = useState(false);

  const storeDream = async () => {
    if (!name) {
      toast.error("Please enter name.");
      return;
    }

    // if (!url) {
    //   toast.error("Please enter redirection url.");
    //   return;
    // }

    if (!description) {
      toast.error("Please enter description.");
      return;
    }

    let formData = new FormData();

    formData.append("image", image);
    formData.append("name", name);
    formData.append("description", description);
    // formData.append("url", url);

    setButtonText("Saving...");
    setIsLoading(true);

    const res = await API.post(`dream/${dream.dreamUniqueId}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    setIsLoading(false);
    setButtonText("Save");

    if (res.message && !res.statusCode) {
      navigateDreamPage();
    }
  };

  const pageContent = (
    <Box>
      <Typography mb={1} sx={{ color: "gray" }}>
        Upload a banner for article. 
      </Typography>
      <Card variant="outlined">
        <form className="form">
          <Box m={3}>
            <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Name"
              variant="outlined"
              fullWidth
              value={name}
              placeholder="Why Do We Need Sleep?"
              type="text"
              onChange={(e) => setName(e.target.value)}
            />
            <br />
            {/* <Typography mt={2} sx={{ color: "gray" }}></Typography>
            <StyledTextField
              label="Article url"
              variant="outlined"
              value={url}
              fullWidth
              placeholder="Sleep is an essential function"
              type="text"
              onChange={(e) => setUrl(e.target.value)}
            />
            <br /> */}
            {/* Banner upload */}
            <Typography mb={1} mt={1} sx={{ color: "gray" }}>
              Upload banner file:
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              NOTE: Upload a banner with a width 335px and height 116px (It is
              preferable to use 335px * 116px.). 
            </Typography>
            <Button
              variant="contained"
              component="label"
              endIcon={<CloudUploadIcon />}
            >
              Upload Banner
              <input
                onChange={(e) => setImage(e.target.files[0])}
                type="file"
                accept="image/*"
                hidden
                name="image"
              />
            </Button>
            <Box mt={1}>
              <img className="img" src={dream.image} alt={"logo"} />
            </Box>
            <br />
            <Typography mb={1} mt={1} sx={{ color: "gray" }}>
              Edit Article description:
            </Typography>
            <CKEditor
              editor={ClassicEditor}
              data={description}
              onReady={(editor) => {
                console.log(editor);
              }}
              config={{
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "blockQuote",
                  "link",
                  "numberedList",
                  "bulletedList",
                  "insertTable",
                  "tableColumn",
                  "tableRow",
                ],
              }}
              onChange={(event, editor) => {
                setDescription(editor.getData());
                console.log(editor.getData());
                console.log("console.log(editor.getData());");
              }}
              onBlur={(event, editor) => {}}
              onFocus={(event, editor) => {}}
            />
            <br />
            <Box sx={{ display: "flex" }} mt={3}>
              <Box>
                <Button
                  disabled={isLoading}
                  onClick={storeDream}
                  variant="contained"
                  color="primary"
                  endIcon={isLoading && <ButtonCircularProgress />}
                >
                  {buttonText}
                </Button>
              </Box>
              <Box ml={2} onClick={navigateDreamPage}>
                <Button variant="outlined" color="primary">
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  );

  return <SideBar pageContent={pageContent} pageTitle="Edit Dream" />;
};

export default EditDream;
