import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
} from "@mui/material";
import SideBar from "../../layouts/Sidebar";
import * as API from "../../services/Api";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { StyledSwitch } from "../../components/StyledSwitch";
import variables from "../../css/variables.scss";
import YouTubeIcon from "@mui/icons-material/YouTube";
import NorthEastIcon from "@mui/icons-material/NorthEast";

const Setting = () => {
  const [formStateExpireTime, setFormStateExpireTime] = useState({
    guestExpireDays: "",
    errors: {
      guestExpireDays: "",
    },
  });
  const [privacyPolicy, setPrivacyPolicy] = useState();
  const [termsOfService, setTermsOfService] = useState();
  const [faq, setFaq] = useState();

  const [privacyPolicyError, setPrivacyPolicyError] = useState();
  const [termsOfServiceError, setTermsOfServiceError] = useState();
  const [faqError, setFaqError] = useState();

  const [loadingExpireTime, setLoadingExpireTime] = useState("");
  const [totalUser, setTotalUser] = useState("");
  const [isShowTrustedByUsersScreen, setIsShowTrustedByUsersScreen] =
    useState(false);

  const [program, setProgram] = useState("");

  const getGuestExpireTime = async () => {
    await API.get(`setting/guest-expire-time`, {})
      .then((response) => {
        setFormStateExpireTime({
          guestExpireDays: response.expireAt,
          errors: {
            guestExpireDays: "",
          },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getShowTrustedByUsersScreen = async () => {
    await API.get("setting/show-trust-user-screen", {})
      .then((response) => {
        setIsShowTrustedByUsersScreen(response.data.isShowTrustedByUsersScreen);
        setTotalUser(response.data.totalUsers);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    getGuestExpireTime();
    getShowTrustedByUsersScreen();
    getProgram();
    getStaticPagesUrl();
  }, []);

  const getProgram = async () => {
    await API.get(`program`, {})
      .then(async (response) => {
        const dailyTraining = await response?.filter(
          (r) => r.name === "Daily Training"
        );

        const sleepPositions = await response?.filter(
          (r) => r.name === "Sleep Positions"
        );

        setProgram({
          dailyTrainingId: dailyTraining[0]?.programId,
          dailyTrainingLink: dailyTraining[0]?.link,
          sleepPositionsId: sleepPositions[0]?.programId,
          sleepPositionsLink: sleepPositions[0]?.link,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getStaticPagesUrl = async () => {
    await API.get(`setting/static-pages`, {})
      .then(async (response) => {
        setPrivacyPolicy(response?.privacyPolicy);
        setTermsOfService(response?.termsOfService);
        setFaq(response?.faq);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleFormChangeExpireTime = (e) => {
    let { name, value } = e.target;
    let errors = formStateExpireTime.errors;

    if (name === "guestExpireDays") {
      if (!(value.length > 0)) {
        errors.guestExpireDays = "* Days is required!";
      } else {
        errors.guestExpireDays = "";
      }
    }
    setFormStateExpireTime({
      ...formStateExpireTime,
      [name]: value,
    });
  };

  const handleExpireTime = (e) => {
    e.preventDefault();

    // URL validation using regular expressions
    const urlPattern = /^(http[s]?:\/\/)(www\.)?[^\s$.?#].[^\s]*$/;
    if (
      !urlPattern.test(program.dailyTrainingLink) ||
      !urlPattern.test(program.sleepPositionsLink)
    ) {
      toast.error("Please enter a valid url");
      return;
    }

    if (!urlPattern.test(privacyPolicy)) {
      setPrivacyPolicyError("* Please enter a valid privacy policy url");
      return false;
    }

    if (!urlPattern.test(termsOfService)) {
      setTermsOfServiceError("* Please enter a valid teams of service url");
      return false;
    }

    if (!urlPattern.test(faq)) {
      setFaqError("* Please enter a valid faq url");
      return false;
    }

    // setLoadingExpireTime(true);

    API.post("setting", {
      guestExpireDays: formStateExpireTime?.guestExpireDays,
      privacyPolicy: privacyPolicy,
      termsOfService: termsOfService,
      faq: faq,
    })
      .then((response) => {
        if (response.data) {
          toast.success("Success");
          setLoadingExpireTime(false);
          // setOpenSnackbar2(true);
        } else {
          setLoadingExpireTime(false);
        }
      })
      .catch((error) => {
        setLoadingExpireTime(false);
      });

    API.post("programs", {
      program: program,
    })
      .then((response) => {
        if (response?.message) {
          setLoadingExpireTime(false);
        } else {
          setLoadingExpireTime(false);
        }
      })
      .catch((error) => {
        setLoadingExpireTime(false);
      });
  };

  const handleTrustedByUsers = async (e) => {
    await API.post(`setting/show-trust-user-screen`, {
      isShowTrustedByUsersScreen: !isShowTrustedByUsersScreen,
    })
      .then((response) => {
        setIsShowTrustedByUsersScreen(!isShowTrustedByUsersScreen);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const pageContent = (
    <>
      <Card variant="outlined">
        <Box m={4} className="form">
          <Box sx={{ display: "flex" }}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              // sx={{ fontWeight: "bold" }}
              sx={{ margin: "0px 15px 0px 0px" }}
              fontSize={18}
            >
              Trust By User
            </Typography>
            <StyledSwitch
              checked={isShowTrustedByUsersScreen}
              onChange={handleTrustedByUsers}
            />
          </Box>

          <Typography variant="caption" display="block" gutterBottom>
            NOTE: If the toggle button is on, then from the splash screen,
            "Trusted by {totalUser} Users!" should display else, it should not
            display.
          </Typography>
        </Box>
        <Box m={4} className="form">
          <form onSubmit={(e) => handleExpireTime(e)}>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              Set Guest User Expire Time
            </Typography>
            <TextField
              type="number"
              // label="Expire Days"
              id="guestExpireDays"
              name="guestExpireDays"
              fullWidth
              InputProps={{
                inputProps: { min: 1 },
                step: 1,
                endAdornment: <Box>Days</Box>,
              }}
              value={formStateExpireTime.guestExpireDays}
              helperText={formStateExpireTime.errors.guestExpireDays}
              onChange={handleFormChangeExpireTime}
            />
            <Typography variant="caption" display="block" gutterBottom>
              NOTE: For guest users, set the expiration time for selected days,
              so if the days are over, then guest user required to login. 
            </Typography>

            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              mt={3}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              Daily Training Youtube Link
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                type="text"
                // label="Daily Training"
                id="dailyTraining"
                name="Daily Training"
                fullWidth
                value={program.dailyTrainingLink}
                helperText={formStateExpireTime.errors.dailyTraining}
                onChange={(e) => {
                  setProgram({ ...program, dailyTrainingLink: e.target.value });
                }}
              />
              <Button
                sx={{ marginLeft: "20px" }}
                variant="outlined"
                component="a"
                href={program.dailyTrainingLink}
                target="_blank"
              >
                <YouTubeIcon />
              </Button>
            </Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              mt={3}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              Sleep Positions Youtube Link
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                // autoFocus
                type="text"
                // label="Sleep Positions"
                id="sleepPositions"
                name="Sleep Positions"
                fullWidth
                value={program.sleepPositionsLink}
                helperText={formStateExpireTime.errors.sleepPositions}
                onChange={(e) => {
                  setProgram({
                    ...program,
                    sleepPositionsLink: e.target.value,
                  });
                }}
              />

              <Button
                style={{ marginLeft: "20px" }}
                variant="outlined"
                component="a"
                href={program.sleepPositionsLink}
                target="_blank"
              >
                <YouTubeIcon />
              </Button>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              mt={3}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              Privacy policy
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                type="text"
                id="privacyPolicy"
                name="Privacy Policy"
                fullWidth
                value={privacyPolicy}
                helperText={privacyPolicyError}
                onChange={(e) => {
                  setPrivacyPolicy(e.target.value);
                  if (e?.target?.value?.length > 0) {
                    setPrivacyPolicyError("");
                  }
                }}
              />
              <Button
                sx={{ marginLeft: "20px" }}
                variant="outlined"
                component="a"
                href={privacyPolicy}
                target="_blank"
              >
                <NorthEastIcon />
              </Button>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              mt={3}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              Terms of Service
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                type="text"
                id="teamsOfService"
                name="Teams of service"
                fullWidth
                value={termsOfService}
                helperText={termsOfServiceError}
                onChange={(e) => {
                  setTermsOfService(e.target.value);
                  if (e?.target?.value?.length > 0) {
                    setTermsOfServiceError("");
                  }
                }}
              />
              <Button
                sx={{ marginLeft: "20px" }}
                variant="outlined"
                component="a"
                href={termsOfService}
                target="_blank"
              >
                <NorthEastIcon />
              </Button>
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              className="show"
              color={variables.baseColor}
              mt={3}
              sx={{ marginBottom: "5px" }}
              fontSize={18}
            >
              FAQ
            </Typography>
            <Box sx={{ display: "flex" }}>
              <TextField
                type="text"
                id="faq"
                name="faq"
                fullWidth
                value={faq}
                helperText={faqError}
                onChange={(e) => {
                  setFaq(e.target.value);
                  if (e?.target?.value?.length > 0) {
                    setFaqError("");
                  }
                }}
              />
              <Button
                sx={{ marginLeft: "20px" }}
                variant="outlined"
                component="a"
                href={faq}
                target="_blank"
              >
                <NorthEastIcon />
              </Button>
            </Box>

            <Box mt={3}>
              <LoadingButton
                loading={loadingExpireTime}
                sx={{
                  ml: { md: 0 },
                  mt: { xs: 1, md: 1 },
                  mb: { xs: 1, md: 1 },
                  width: "150px",
                }}
                loadingIndicator={
                  <CircularProgress color="secondary" size={25} />
                }
                type="submit"
                variant="contained"
              >
                Update
              </LoadingButton>
            </Box>
          </form>
        </Box>
      </Card>
    </>
  );

  return <SideBar pageContent={pageContent} pageTitle="Setting" />;
};

export default Setting;
