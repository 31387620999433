import CategoryIcon from "@mui/icons-material/Category";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import ArticleIcon from "@mui/icons-material/Article";

export const menuItems = [
  {
    title: "Categories",
    icon: <CategoryIcon />,
    items: [
      {
        title: "White Noise",
        link: "/admin/category/white-noise",
      },
      {
        title: "Music",
        link: "/admin/category/music",
      },
      {
        title: "SoundScape",
        link: "/admin/category/soundscape",
      },
      {
        title: "Meditation",
        link: "/admin/category/meditation",
      },
      {
        title: "Story",
        link: "/admin/story",
      },
      {
        title: "Artists",
        link: "/admin/artist",
      },
      {
        title: "Video",
        link: "/admin/video",
      },
    ],
  },
  {
    title: "Articles",
    icon: <ArticleIcon />,
    link: "/admin/articles",
    items: [],
  },
  {
    title: "Banners",
    icon: <ViewCarouselIcon />,
    link: "/admin/banners",
    items: [],
  },
];

export function hasChildren(item) {
  const { items: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}
