import { Button, IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MUIDataTable from "mui-datatables";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as API from "../../../services/Api";
import { Delete, Edit, LibraryAdd } from "@mui/icons-material";
import SideBar from "../../../layouts/Sidebar";
import { SubcaetegoriesTypeEnum } from "../../../config/enums";
import DeleteDialog from "../../../components/DeleteDialog";
import { datatableRowOptions } from "../../../config/constant";
import StyledButton from "../../../components/StyledButton";

const initialState = {
  page: 0,
  total: 0,
  limit: 15,
  data: [["Loading Data..."]],
  isLoading: false,
};

const WhiteNoise = (props) => {
  const { category } = props;

  console.log("category", category);
  const [state, setState] = useState(initialState);

  const deleteDialogInitialState = {
    isOpen: false,
    rowData: null,
  };
  const [deleteSubcategoryPopUpDialog, setDeleteSubcategoryPopUpDialog] =
    useState(deleteDialogInitialState);

  const getSubCategories = async (page = 1, limit = state.limit) => {
    setState({
      ...state,
      isLoading: true,
    });

    const subcategories = await API.get(
      `subcategories?categoryId=${category.id}&page=${page}&limit=${limit}`
    );
    setState({
      ...state,
      limit,
      page: subcategories.meta.currentPage,
      total: subcategories.meta.totalItems,
      data: subcategories.items,
      isLoading: false,
    });
  };

  useEffect(() => {
    getSubCategories();
  }, [category]);

  const handleDeleteSubcategoryPopUp = (rowData) => {
    setDeleteSubcategoryPopUpDialog({
      isOpen: true,
      rowData,
    });
  };

  const deleteSubcategory = async () => {
    const subcategory = deleteSubcategoryPopUpDialog.rowData;
    await API.remove(`subcategories/${subcategory.subcategoryUniqueId}`);
    setDeleteSubcategoryPopUpDialog(deleteDialogInitialState);
    getSubCategories(state.page, state.limit);
  };

  const handlePopUpClose = () => {
    setDeleteSubcategoryPopUpDialog(deleteDialogInitialState);
  };

  const columns = [
    {
      name: "subcategoryUniqueId",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "name",
      label: "Name",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];

          return (
            <Typography sx={{ lineHeight: "28px", fontSize: "0.875rem" }}>
              {data.name}
            </Typography>
          );
        },

        filter: true,
        sort: true,
      },
    },
    {
      name: "subcategoryUniqueId",
      label: "Add Musics",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];
          if (data.type === "mix") {
            return (
              <Button
                onClick={() =>
                  navigate(`/admin/musics/white-noise/mixes`, {
                    state: { category, subcategoryId: data.subcategoryId },
                  })
                }
                size="small"
                sx={{ fontSize: "0.875rem", textTransform: "capitalize" }}
                variant="outlined"
                autoFocus
              >
                Manage Mixes
              </Button>
            );
          }
          return (
            <Button
              onClick={() =>
                navigate(`/admin/musics/white-noise`, {
                  state: { category, subcategoryId: data.subcategoryId },
                })
              }
              sx={{ fontSize: "0.875rem", textTransform: "capitalize" }}
              size="small"
              variant="outlined"
              autoFocus
            >
              Manage Musics
            </Button>
          );
        },
        filter: true,
        sort: true,
      },
    },
    {
      name: "subcategoryUniqueId",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const data = state.data[dataIndex];
          if (data.type !== SubcaetegoriesTypeEnum.MIX) {
            return (
              <Box>
                <IconButton
                  onClick={() => handleDeleteSubcategoryPopUp(data)}
                  aria-label="delete"
                  sx={{ color: "#d11a2a" }}
                >
                  <Delete />
                </IconButton>
                <IconButton
                  onClick={() =>
                    navigate(`/admin/subcategories/${data.subcategoryUniqueId}`)
                  }
                  aria-label="edit"
                  color="primary"
                >
                  <Edit />
                </IconButton>
              </Box>
            );
          }
        },
        filter: true,
        sort: true,
      },
    },
  ];

  const options = {
    rowsPerPage: state.limit,
    search: false,
    count: state.total,
    ...datatableRowOptions,
    onTableChange: async (action, tableState) => {
      // if (action === "changePage") {
      //   getSubCategories(tableState.page + 1, state.limit);
      // }
      if (action === "changePage") {
        await getSubCategories(tableState.page + 1, tableState.rowsPerPage);
      }
      if (action === "changeRowsPerPage") {
        await getSubCategories(tableState.page + 1, tableState.rowsPerPage);
      }
    },
  };

  const navigate = useNavigate();

  const navigateAddSubcategory = () => {
    console.log(category);
    navigate(
      `../../admin/category/${category.categoryUniqueId}/subcategories`,
      {
        state: { category },
      }
    );
  };

  const pageContent = (
    <div className="App wrapper">
      <Box m={1} display="flex" justifyContent="flex-end" alignItem="flex-end">
        <StyledButton
          label="Add Subcategory"
          onClick={navigateAddSubcategory}
          endIcon={<LibraryAdd />}
        />
      </Box>

      <MUIDataTable data={state.data} columns={columns} options={options} />

      <DeleteDialog
        open={deleteSubcategoryPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteSubcategory}
        title="Delete subcategory?"
        body="All the music in the selected category will be permanently deleted.
        Are you sure you want to delete the subcategory permanently?"
      />

      <DeleteDialog
        open={deleteSubcategoryPopUpDialog.isOpen}
        onClose={handlePopUpClose}
        onDeleteClick={deleteSubcategory}
        title="Delete subcategory?"
        body="All the music in the selected category will be permanently deleted.
        Are you sure you want to delete the subcategory permanently?"
      />
    </div>
  );

  return <SideBar pageContent={pageContent} pageTitle={category.name} />;
};

export default WhiteNoise;
