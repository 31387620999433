import { Button } from "@mui/material";
import React from "react";

const StyledButton = ({ endIcon, onClick, label, startIcon }) => {
  return (
    <Button
      variant="contained"
      size="small"
      sx={{ textAlign: "right" }}
      onClick={onClick}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {label}
    </Button>
  );
};

export default StyledButton;
